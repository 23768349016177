import React, { useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import Zoom from '@mui/material/Zoom';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/ForumOutlined';
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { SxProps } from '@mui/system';

import useInitialization from 'features/Chat/hook/useInitialization';
import ChatView from 'features/Chat/ChatView';
import store from 'features/Chat/store/ChatStore';

const fabStyle = {
  position: 'absolute',
  bottom: 13,
  right: 13,
  zIndex: 9999,
};

const fabs = [
  {
    sx: fabStyle as SxProps,
    icon: <ChatIcon />,
    label: 'Chat',
    size: 'small',
  },
  {
    sx: fabStyle as SxProps,
    icon: <DownIcon />,
    label: 'Hide',
    size: 'small',
  },
];

const transitionDuration = {
  enter: 0.225,
  exit: 0.195,
};

const AppChat: React.FC = () => {
  // const { classes, cx } = useStyles();
  const state = useSnapshot(store);

  const address = localStorage.getItem('wallet');
  // Step 1 - Initialize wallets and wallet connect client
  // Temporarily commment it out
  const initialized = useInitialization(address);

  // Step 2 - Once initialized, set up wallet connect event manager
  // useWalletConnectEventsManager(initialized);

  const onChatRequest = () => {
    store.open = !state.open;
  };

  return (
    <>
      {fabs.map((fab, index) => (
        <Zoom
          key={fab.label}
          in={(!state.open && index === 0) || (state.open && index === 1)}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${state.open ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
          <Fab
            sx={fab.sx}
            size="small"
            color="primary"
            aria-label={fab.label}
            onClick={onChatRequest}
          >
            {fab.icon}
          </Fab>
        </Zoom>
      ))}
      {state.open && <ChatView />}
    </>
  );
};

export default AppChat;
