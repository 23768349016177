// @ts-nocheck
import React, { useState, useCallback } from 'react';
import { useSnapshot } from 'valtio';
import { AppBar, IconButton, Toolbar, Button, Box, Stack } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from 'tss-react/mui';
import store, { getWindow, hideWindow, showWindow } from 'store/windowStore';
import appStore from 'store/appStore';

import { useTranslation } from 'react-i18next';

// const buttonStyles = makeStyles((theme) => ({
//   root: {
//     marginRight: theme.spacing(2),
//     maxWidth: 168,
//     maxHeight: 34,
//   },
//   label: {
//     overflow: 'hidden',
//     whiteSpace: 'nowrap',
//     display: 'inline-block',
//     textOverflow: 'ellipsis',
//     // animation: `$marquee 4s linear infinite`,
//   },
//   // '@keyframes marquee': {
//   //   '0%': {
//   //     transform: 'translateX(0)',
//   //     marginLeft: 0,
//   //   },
//   //   '100%': {
//   //     transform: 'translateX(-100%)',
//   //     // marginLeft: '-100%',
//   //   },
//   // },
// }));

const ToolbarWindowItem: React.FC<{ id: string; focused: string }> = ({
  id,
  focused,
}) => {
  const { classes, cx } = useStyles();

  const win = getWindow(id);
  if (!win) {
    return null;
  }

  return (
    <Button
      className={classes.label}
      variant={id === focused ? 'contained' : 'outlined'}
      disableElevation
      onClick={() => (id === focused ? hideWindow(id) : showWindow(id))}
    >
      {win.title}
    </Button>
  );
};

const AppTaskbar: React.FC = () => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();

  const showDrawer = () => (appStore.modal.drawer = true);

  const state = useSnapshot(store);
  const focused = state.focused;

  return (
    <AppBar position="fixed" color="default" className={cx(classes.appBar)}>
      <Toolbar className={cx(classes.toolbar)}>
        <IconButton
          size="small"
          edge="start"
          color="inherit"
          aria-label={t('app_sidebar.open')}
          sx={{ mr: 2 }}
          onClick={showDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          sx={{ width: 'calc(100% - 97px)' }}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={0.5}
        >
          {Array.from(state.windows.keys()).map((id) => (
            <ToolbarWindowItem key={id} id={id} focused={focused} />
          ))}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles()((theme) => ({
  appBar: {
    position: 'absolute',
    top: 'auto',
    bottom: 0,
    height: 38,
    paddingTop: 3,
    paddingBottom: 2,
    background: '#f7f7f7',
    borderTop: 'solid 1px rgba(0,0,0,.05)',
  },
  toolbar: {
    minHeight: '23px !important',
    marginRight: theme.spacing(4),
  },
  windowTabs: {
    flex: '1 1 0%',
    width: 0,
    padding: theme.spacing(2, 0),
    whiteSpace: 'nowrap',
    overflow: 'auto hidden',
  },
  buttons: {
    marginRight: theme.spacing(2),
    maxWidth: 168,
    maxHeight: 34,
  },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    // animation: `$marquee 4s linear infinite`,
  },
}));

export default AppTaskbar;
