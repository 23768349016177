// @ts-nocheck
import loadable from '@loadable/component';
import { useState } from 'react';
import { useSnapshot } from 'valtio';
import { useTranslation } from 'react-i18next';
import { safeExecute } from 'helper/utils';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import StorageIcon from '@mui/icons-material/Storage';
import SupportIcon from '@mui/icons-material/Support';
import ShareIcon from '@mui/icons-material/Share';
import GroupIcon from '@mui/icons-material/Group';
import WifiIcon from '@mui/icons-material/Wifi';
import PostAddIcon from '@mui/icons-material/PostAdd';

import appStore from 'store/appStore';
import {
  marketplace,
  sharestepper,
  accountstepper,
  journalstepper,
  reportstepper,
  authorizestepper,
  importstepper,
  hasWindow,
  createWindow,
  setFocus,
} from 'store/windowStore';

const AppWizard = () => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(1);
  const appState = useSnapshot(appStore);
  const license = JSON.parse(appState.license || '{"LA": 0}');

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    let id;
    switch (index) {
      case 0:
        id = marketplace;
        if (!hasWindow(id)) {
          createWindow(
            id,
            {
              title: t('menu.Marketplace'),
              Component: loadable(() => import(`views/Market/Marketplace`)),
            },
            true
          );
        }
        break;
      case 1:
        id = accountstepper;
        if (!hasWindow(id)) {
          safeExecute(() =>
            createWindow(
              id,
              {
                title: t('account.title'),
                Component: loadable(
                  () => import(`views/Stepper/AccountStepper`)
                ),
              },
              true
            )
          );
        }
        break;
      case 2:
        id = journalstepper;
        if (!hasWindow(id)) {
          safeExecute(() =>
            createWindow(
              id,
              {
                title: t('journal.title'),
                Component: loadable(
                  () => import(`views/Stepper/JournalStepper`)
                ),
              },
              true
            )
          );
        }
        break;
      case 3:
        id = reportstepper;
        if (!hasWindow(id)) {
          safeExecute(() =>
            createWindow(
              id,
              {
                title: t('report.title'),
                Component: loadable(
                  () => import(`views/Stepper/ReportStepper`)
                ),
              },
              true
            )
          );
        }
        break;
      case 4:
        id = authorizestepper;
        if (!hasWindow(id)) {
          safeExecute(() =>
            createWindow(
              id,
              {
                title: t('authorize.title'),
                Component: loadable(
                  () => import(`views/Stepper/AuthorizeStepper`)
                ),
              },
              true
            )
          );
        }
        break;
      case 5:
        id = importstepper;
        if (!hasWindow(id)) {
          safeExecute(() =>
            createWindow(
              id,
              {
                title: t('import.title'),
                Component: loadable(
                  () => import(`views/Stepper/ImportStepper`)
                ),
              },
              true
            )
          );
        }
        break;
      case 6:
        id = sharestepper;
        if (!hasWindow(id)) {
          createWindow(
            id,
            {
              title: t('knowhow_market.sharesell.title'),
              Component: loadable(() => import(`views/Market/ShareStepper`)),
            },
            true
          );
        }

        break;
      case 7:
        id = 'contact-support';
        if (!hasWindow(id)) {
          createWindow(
            id,
            {
              title: t('support.action'),
              Component: loadable(() => import(`features/Support/Support`)),
            },
            true
          );
        }
        break;
      default:
    }

    if (id) {
      setFocus(id);
    }
  };

  return (
    <Box
      style={{
        position: 'absolute',
        right: 18,
        bottom: 58,
        width: 238,
        background: 'transparent',
      }}
    >
      <List component="nav" aria-label="main mailbox folders">
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={(event) => handleListItemClick(event, 0)}
        >
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.shop_marketplace')} />
        </ListItemButton>
        <Divider />

        <ListItemButton
          disabled={license.LA === 0}
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1)}
        >
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.setup_account')} />
        </ListItemButton>
        <ListItemButton
          disabled={license.LA === 0}
          selected={selectedIndex === 2}
          onClick={(event) => handleListItemClick(event, 2)}
        >
          <ListItemIcon>
            <PostAddIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.setup_journal')} />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={(event) => handleListItemClick(event, 3)}
        >
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.setup_report')} />
        </ListItemButton>
        <Divider />
        <ListItemButton
          disabled={license.LA === 0}
          selected={selectedIndex === 4}
          onClick={(event) => handleListItemClick(event, 4)}
        >
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.delegate')} />
        </ListItemButton>
        <ListItemButton
          selected={selectedIndex === 5}
          onClick={(event) => handleListItemClick(event, 5)}
        >
          <ListItemIcon>
            <WifiIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.import')} />
        </ListItemButton>

        <ListItemButton
          selected={selectedIndex === 6}
          onClick={(event) => handleListItemClick(event, 6)}
        >
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText primary={t('menu.share')} />
        </ListItemButton>

        <Divider />

        <ListItemButton
          selected={selectedIndex === 7}
          onClick={(event) => handleListItemClick(event, 7)}
        >
          <ListItemIcon>
            <SupportIcon />
          </ListItemIcon>
          <ListItemText primary={t('support.action')} />
        </ListItemButton>
      </List>
    </Box>
  );
};

export default AppWizard;
