import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
// import Web3 from "web3";
import { ethers } from 'ethers';
import { utils, Contract, Web3Provider, Provider, Wallet } from 'zksync-web3';
import MetaMaskOnboarding from '@metamask/onboarding';

import { getError } from 'helper/error';
import store, { isMetaMaskInstalled } from '../store/';
import { getDidAddress, formatMessage, ascii_to_hex } from '../utils/address';
import { error, notice, confirm } from '@knowins/notifier';

export const metamask = 'metamask';
export const zkSyncEraChains = [280, 324, '0x118', '0x144'];
export const hasMetaMask = isMetaMaskInstalled();

export const getAccount = () => getDidAddress(store.session?.p?.iss);

export const checkNetwork = async (chainId: number) => {
  if (hasMetaMask) {
    const currentChainId = await window.ethereum.request({
      method: 'eth_chainId',
    });

    const targetChainID = ethers.utils.hexValue(
      typeof chainId === 'string' ? parseInt(chainId) : chainId
    );
    return targetChainID === currentChainId;
  }
};

export const switchNetwork = async (chainId: number) => {
  try {
    const targetChainID = chainId;
    const err = await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: ethers.utils.hexValue(targetChainID) }],
    });

    if (err === null) {
      return true;
    }

    return err.message;
  } catch (e: any) {
    switch (e.code) {
      case -32601:
        return 'Please switch blockchain network manually';
      default:
        return e.message;
    }
  }
};

export const ensurChainId = async (
  chainId: number,
  setProgress?: (any?: any) => void
) => {
  const validBlockchain = await checkNetwork(chainId);
  if (!validBlockchain) {
    if (typeof setProgress === 'function') {
      setProgress();
    }

    return await switchNetwork(chainId);
  }

  return true;
};

export const onMetaMaskConnect = async (
  args: any = undefined,
  forcedChain = false
) => {
  const chainId = parseInt(store.chainId.split(':')[1]);
  try {
    if (!hasMetaMask) {
      confirm('action.install_metamask', () => {
        // const forwarderOrigin = 'https://fwd.metamask.io';
        // const onboarding = new MetaMaskOnboarding({ forwarderOrigin });
        const onboarding = new MetaMaskOnboarding();
        onboarding.startOnboarding();
      });
    } else {
      // const isCorrectChainID = await checkNetwork(chainId);
      // if (!isCorrectChainID) {
      //   confirm(
      //     'action.switch_wrong_chain',
      //     async () => {
      //       const success = await switchNetwork(chainId);
      //       if (success) {
      //         window.location.reload();
      //       }
      //     },
      //     () => {
      //       if (forcedChain) {
      //         notice('action.switch_chain_mannually');
      //       }
      //     }
      //   );
      // }
      const isCorrectChainID = true;

      if (isCorrectChainID || !forcedChain) {
        const eth = window.ethereum;
        // Will open the MetaMask UI
        // You should disable this button while the request is pending!
        const accounts = await eth
          .request({
            method: 'eth_requestAccounts',
          })
          .then((res) => {
            console.log('res:', res);
            return res;
          })
          .catch((e) => {
            console.log('e............', e);
            switch (e.code) {
              case 4001:
                // EIP-1193 userRejectedRequest error
                notice('action.connect_metamask');
                break;
              case -32002:
                notice('status.already_in_processing');
                break;
              default:
                error(e);
            }
          });

        if (!accounts) {
          return error('status.mm_connect_error');
        }

        // eth.on('chainChanged', (chainId) => {
        //   // Handle the new chain.
        //   // Correctly handling chain changes can be complicated.
        //   // We recommend reloading the page unless you have good reason not to.
        //   window.location.reload();
        // });

        const walletAddress = accounts[0];
        const iss = `eip155:${chainId}:did:pkh:${walletAddress}`;
        const domain = window.location.hostname.split('.').slice(-2).join('.');

        store.session = {
          p: {
            domain,
            version: '1',
            nonce: 'na',
            aud: window.location.href,
            type: 'eip4361',
            statement:
              'Welcome to KNOWINS. Signing is the only way we can truly know that you are the owner of the wallet you are connecting. Signing is a safe, gas-less transaction that does not in any way give permission to perform any transactions with your wallet.',
            iat: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
            iss,
          },
          s: {},
          topic: null,
        };

        return accounts[0];
      }

      return null;
    }
  } catch (error) {
    console.log(error);
  }
};

export default function useMetaMask({ version, connect, delegation = false }) {
  const state = useSnapshot(store);
  const { session, selected, args } = state;

  const onSession = useCallback(
    async (session) => {
      try {
        if (session) {
          console.log('session topic', session.topic);
          const result = await connect(session, args);
          // console.log('connect...........', result);
          if (!delegation) {
            store.open = false;
          } else {
            if (Array.isArray(result) && result.length > 0) {
              console.log('Found delegations:', result);
              store.authorization = result;
              store.open = true;
            } else {
              store.open = false;
            }
          }
        }
      } catch (err: any) {
        console.log(err);
        error(err);
      }
    },
    [args]
  );

  useEffect(() => {
    if (selected.length > 0) {
      connect({ ...state.session, username: selected }, true);
    }
  }, [selected]);

  useEffect(() => {
    onSession(session);
  }, [session]);
}

let nonce = 1;
export async function loginRequest() {
  try {
    const eth = window.ethereum;
    const iss = store.session.p.iss;
    const from = getDidAddress(iss);
    const token = formatMessage({ ...store.session.p, nonce: nonce++ }, iss);
    console.log(token);
    //const msg = ascii_to_hex(`Login with secured code: ${captcha}`);
    const msg = ascii_to_hex(token);
    const signature = await eth.request({
      method: 'personal_sign',
      params: [msg, from, 'Login'],
    });

    return {
      success: true,
      signature,
    };
  } catch (e: any) {
    console.error(e);
    switch (e.code) {
      case 4001:
        store.session = null;
        throw 'status.mm_login_rejected';
      default:
    }
    throw 'status.something_wrong';
  }
}

export async function request(
  from: string,
  to: string,
  data: string,
  amount: string,
  gasPrice: string,
  estimatedGas: string,
  EIP712Msg: any,
  chainId: number,
  fromAddr: string,
  type: number,
  nonce: number,
  customData: any,
  gas: string | number,
  gasLimit: string | number,
  maxFeePerGas: string | number,
  maxPriorityFeePerGas: string | number,
  setProgress?: (any?: any) => void
) {
  if (hasMetaMask) {
    const eth = window.ethereum;
    const message = await ensurChainId(
      chainId || EIP712Msg?.domain?.chainId,
      () => (typeof setProgress === 'function' ? setProgress() : undefined)
    );
    if (message !== true) {
      return {
        success: false,
        message,
      };
    } else {
      try {
        if (!EIP712Msg) {
          let tx;
          // if (!tx.gas) {
          //console.log("store.l1Rpc", store.l1Rpc);
          // const web3 = new Web3(store.l1Rpc);

          //   tx.gas = web3.utils.toHex(web3.utils.toWei(String(gas), 'wei'));
          // }
          if (!customData) {
            console.log('legacy transaction');

            // web3.eth.estimateGas({
            //   to: toAddress,
            //   data: "0xc6888fa10000000000000000000000000000000000000000000000000000000000000003"
            // }).then((estimatedGas) => {
            //   web3.eth.getMaxPriorityFeePerGas().then((price) => {
            //     sendTx(web3, {
            //       gas: estimatedGas,
            //       maxPriorityFeePerGas: price,
            //       to: toAddress,
            //       value: 100,
            //     });
            //   });
            // });

            tx =
              type == 2
                ? {
                    from,
                    to,
                    // gas: estimatedGas || gasLimit, // When deposit usdc on L1 Metamask throw error: invalid key
                    gasLimit: estimatedGas || gasLimit,
                    maxFeePerGas,
                    maxPriorityFeePerGas,
                    value: amount,
                    data,
                    type: '2',
                    // chainId,
                  }
                : {
                    from,
                    to,
                    // gas: estimatedGas || gasLimit, // When deposit usdc on L1 Metamask throw error: invalid key
                    gasLimit: gasLimit || estimatedGas,
                    gasPrice: gasPrice || gas || maxFeePerGas,
                    value: amount,
                    data,
                    // chainId,
                  };

            // const gas1 = await web3.eth.estimateGas(tx);
            // console.log("gas1...", gas1);
            // if (!tx.gasLimit) {
            //   tx.gasLimit = web3.utils.toHex(
            //     web3.utils.toWei(String(gas1), "wei")
            //   );
            // }

            // if (type) {
            //   tx.type = type.toString();
            // }
          } else {
            console.log('paymaster transaction');

            tx = {
              from,
              to,
              gas: estimatedGas || gasLimit,
              // gasPrice: gasPrice || gas, // When donate usdc on L2 Metamask throw error: both gasPrice and (maxFeePerGas or maxPriorityFeePerGas) specified
              // gasLimit,
              value: amount,
              data,
              nonce: ethers.utils.hexValue(nonce), // MetaMask - RPC Error: Invalid transaction params: nonce is not a string
              customData,
              maxFeePerGas,
              maxPriorityFeePerGas,
            };
          }
          console.log('tx to send to metamask........', tx);

          let transactionHash;
          if (zkSyncEraChains.findIndex((id) => id == chainId) === -1) {
            const txHash = await eth.request({
              method: 'eth_sendTransaction',
              params: [tx],
            });
            console.log('txHash........', txHash);
            transactionHash = txHash;
          } else {
            // const zkSyncProvider = new Provider("https://mainnet.era.zksync.io");
            // const signer = new Web3Provider(zkSyncProvider).getSigner();
            const signer = new Web3Provider(window.ethereum).getSigner();

            const txHash = await signer.sendTransaction(tx);
            console.log('txHash........', txHash);
            const receipt = await txHash.wait();
            console.log('receipt........', receipt);
            transactionHash = receipt.blockHash;
          }
          console.log('transactionHash........', transactionHash);

          return {
            success: true,
            transaction_hash: transactionHash,
          };
        } else {
          console.log('EIP712Msg', EIP712Msg);

          // const txHash = await eth.request({
          //   method: "eth_signTransaction",
          //   params: [tx],
          // });

          const signature = await eth.request({
            method: 'eth_signTypedData_v4',
            params: [from, JSON.stringify(EIP712Msg)],
          });

          console.log('signature:', signature, from);
          const zkSyncProvider = new Provider('https://testnet.era.zksync.dev');
          // const zkSyncProvider = new Provider("https://mainnet.era.zksync.io");

          const tx = {
            //txType: 113,
            from,
            to,
            gasLimit: estimatedGas || gasLimit,
            maxFeePerGas,
            maxPriorityFeePerGas,
            nonce,
            value: ethers.BigNumber.from(0),
            data,
            type: 113,
            chainId: parseInt(chainId || EIP712Msg?.domain?.chainId),
            customData: {
              ...customData,
              customSignature: signature,
            },
          };

          console.log('tx...........', tx);

          const serializedTx = utils.serialize({ ...tx });
          console.log('serializedTx...........', serializedTx);

          const sentTx = await zkSyncProvider.sendTransaction(serializedTx);
          console.log('......sentTx', sentTx);
          await sentTx.wait();

          // return {
          //   success: true,
          //   EIP712Msg,
          //   signature,
          // };
        }
      } catch (e: any) {
        console.log('............', e, e.code);
        if (e && e.code) {
          switch (e.code) {
            case 4001:
            case 'ACTION_REJECTED': // MetaMask Typed Message Signature: User denied message signature.
              return {
                success: false,
                message: 'status.wc_payment_rejected',
              };
            // case -32000:
            //   return {
            //     success: false,
            //     message: e.message,
            //   };
            default:
          }
        }
        return {
          success: false,
          message: getError(e) || 'status.something_wrong',
        };
      }
    }
  }
}
