import { error, info } from '@knowins/notifier';
import appStore from 'store/appStore';
import CONFIG from 'constant/common';

export const InitTbarLocal = function (tbar, btNames, btOffs) {
  const buttons = tbar.query('button');
  const vm = tbar.lookupViewModel();
  // NOTED:
  //    If button = 'View' Then button.itemId = 'btView'
  //    btOffs: Array contains invisible buttons {'View'}
  let s = '';
  buttons.forEach(function (btn) {
    const btName = btn.itemId.substring(2);
    if (!Ext.Array.contains(btNames, btName) && btn.itemId !== 'btLocker') {
      tbar.remove(btn);
    } else {
      // btn.setVisible(!btOffs || !Ext.Array.contains(btOffs, btName));
      vm.set(`toolbar.${btName.toLowerCase()}`, !btOffs.includes(btName));
    }
  });
};

export const setupTbar = (view, tbar) => {
  const buttons = tbar.query('button');
  const onList = CONFIG.TbarView[view];

  if (onList) {
    const vm = tbar.lookupViewModel();
    buttons.forEach((btn) => {
      const name = btn.itemId.substr(2); // remove prefix 'bt'
      if (name !== 'Locker' && name !== 'Lock' && name !== 'Unlock') {
        const led = Ext.Array.contains(onList, name);
        vm.set(`toolbar.${name.toLowerCase()}`, led);
        // btn.setVisible(led);
      }
    });
  }
};

export const enact = (vm, tbar, enable, view) => {
  if (view && view.setLoading) {
    view.setLoading(!enable);
  }

  if (vm) {
    const buttons = tbar.query('button');
    if (buttons) {
      buttons.forEach(function (btn) {
        vm.set(`toolbar._${btn.itemId.substring(2).toLowerCase()}`, enable);
      });
    }
  }
};

export const buildTreeNodeFromDDL = (record, store, parentNode) => {
  record.nodes().each((node) => {
    if (node.get('type') !== 'S') {
      parentNode.appendChild({
        id: parentNode.id + '\\' + node.get('node'),
        code: node.get('node'),
        type: node.get('type'),
        text: node.get('name'),
        nodedesc: node.get('nodedesc'),
        leaf: true,
        //cls: 'fox-tree-node-black',
        iconCls: 'fox-tree-noicon',
      });
    } else {
      const childNode = parentNode.appendChild({
        id: parentNode.id + '\\' + node.get('node'),
        code: node.get('node'),
        type: node.get('type'),
        text: node.get('name'),
        nodedesc: node.get('nodedesc'),
        leaf: false,
        //cls: 'fox-tree-node-black',
        iconCls: 'fox-tree-noicon',
      });
      const code = node.get('node');
      const sNode = code.substr(code.lastIndexOf('\\') + 1);
      const rec = store.findRecord('ddlcode', sNode);

      if (rec) {
        buildTreeNodeFromDDL(rec, store, childNode);
      }
    }
  });

  return parentNode;
};

export const loadTreeQuery = (ddlcode, store, tree) => {
  const record = store.findRecord('ddlcode', ddlcode);
  if (record) {
    const root = tree.getRootNode();
    root.removeAll();
    root.setId(record.get('ddlcode'));

    Ext.suspendLayouts(); // To speed up the processing

    buildTreeNodeFromDDL(record, store, root);

    Ext.resumeLayouts();
  }
};

export const getFormState = (form) => {
  const vm =
    typeof form.lookupViewModel === 'function'
      ? form.lookupViewModel()
      : form.getViewModel();

  return vm ? vm.get('processStatus') : form.processStatus;
};

export const setFormMode = (form, processStatus) => {
  form.processStatus = processStatus;

  const vm =
    typeof form.lookupViewModel === 'function'
      ? form.lookupViewModel()
      : form.getViewModel();

  if (vm) {
    vm.set('processStatus', processStatus);
    switch (processStatus) {
      case 'C':
      case 'A':
      case 'O':
        vm.set('editable', true);
        break;
      default:
        vm.set('editable', false);
    }
  }

  const reactComponents = form.query('reactcomponent');
  switch (processStatus) {
    case 'C':
    case 'A':
    case 'O':
      reactComponents &&
        reactComponents.forEach((r) => {
          appStore.legacy.views[r.id].readOnly = false;
        });
      break;
    default:
      reactComponents &&
        reactComponents.forEach((r) => {
          appStore.legacy.views[r.id].readOnly = true;
        });
  }
};

export const isViewMode = (form, state) => {
  return state !== undefined ? state === 'V' : getFormState(form) === 'V';
};

export const setViewMode = (form) => {
  setFormMode(form, 'V');
};

export const isEditMode = (form) => {
  return getFormState(form) === 'A';
};

export const setEditMode = (form) => {
  setFormMode(form, 'A');
};

export const isAddMode = (form, state = false) => {
  return state ? state === 'C' : getFormState(form) === 'C';
};

export const setAddMode = (form) => {
  setFormMode(form, 'C');
};

export const isSaveMode = (form, state) => {
  return state !== undefined ? state === 'S' : getFormState(form) === 'S';
};

export const setSaveMode = (form) => {
  setFormMode(form, 'S');
};

export const isCloneMode = (form) => {
  return getFormState(form) === 'O';
};

export const setCloneMode = (form) => {
  setFormMode(form, 'O');
};

export const isListMode = (form, state) => {
  return state !== undefined ? state === '' : !getFormState(form);
};

export const setListMode = (form) => {
  setFormMode(form, '');
};

export const onFieldValidityChange = (form, isValid) => {
  if (form.processStatus != 'C' || form.processStatus !== 'O') {
    if (!isValid) {
      error('validate.invalid_field_value');
    }
  }
};

export const copyToClipboard = (text) => {
  if (navigator && navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => {
        info('status.coppied_to_clipboard');
      },
      () => {
        info('status.copy_to_console');
      }
    );
  }
};

export const currencyFormat = (value, alias) => {
  var format = '0,000.00';
  switch (alias) {
    case 'VND':
      format = '0,000';
      break;
    case 'USD':
      format = '0,000.00';
      break;
    case 'BTC':
      format = '0,000.0000';
      break;
    default:
      return value;
  }

  return Ext.util.Format.number(value, format);
};
