// @ts-nocheck

import loadable from '@loadable/component';
import { useCallback, useState } from 'react';
import {
  hasWindow,
  showWindow,
  createWindow,
  createExtWindow,
  closeAll,
} from 'store/windowStore';
import {
  Button,
  Avatar,
  Box,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';

import { GetConfigData, GetConfigMenuItem, useLogout } from 'api';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { executePromise, warn } from '@knowins/notifier';
import { safeExecute, readLocalStorage } from 'helper/utils';

import './overlayscrollbars.css'; // 'overlayscrollbars/overlayscrollbars.css'; // 'overlayscrollbars/css/OverlayScrollbars.css';

import appStore from 'store/appStore';
export const hideDrawer = () => (appStore.modal.drawer = false);

const AppSidebarItem: React.FC<{
  item: GetConfigMenuItem;
  level: number;
  report: boolean;
}> = ({ item, level, report }) => {
  const { t } = useTranslation();
  const { menuid } = item;
  const text = report ? item.text : t(`menu.${item.text}`);

  const hasChildren = Boolean(item.children?.length);
  const [expanded, setExpanded] = useState(false);

  const onClick = useCallback(() => {
    const hasChildren = Boolean(item.children?.length);
    if (hasChildren) {
      return setExpanded((ex) => !ex);
    }
    if (hasWindow(menuid)) {
      showWindow(menuid);
    } else {
      const title = text;
      if (item.xtype === 'react') {
        createWindow(
          menuid,
          {
            title,
            Component: loadable(() => import(`views/${item.xclass}/index`)),
          },
          true
        );
      } else if (item.xclass || item._xclass) {
        item.title = title;
        if (item.xclass === 'MoneFox.view.rp.reportviewer.ReportViewerPanel') {
          safeExecute(() => createExtWindow(menuid, item, null, false));
        } else {
          safeExecute(() => createExtWindow(menuid, item));
        }
      } else {
        warn('status.no_show');
      }
    }

    hideDrawer();
  }, [item, menuid, text]);

  return (
    <>
      <ListItem disablePadding dense onClick={onClick}>
        <ListItemButton key={menuid}>
          {hasChildren ? (
            <ListItemText primary={text} />
          ) : (
            <ListItemText secondary={text} />
          )}
          {hasChildren ? expanded ? <ExpandLess /> : <ExpandMore /> : undefined}
        </ListItemButton>
      </ListItem>
      {item.children && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((childItem, idx) => (
              <AppSidebarItem
                key={childItem.menuid}
                item={childItem}
                level={level + 1}
                report={report}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

const AppSidebarItems: React.FC = () => {
  const { t } = useTranslation();
  const state = useSnapshot(appStore);
  const menu = JSON.parse(state.menu);

  const existingLeaf = (m: any) => {
    if (!Array.isArray(m)) {
      m = [m];
    }

    for (let i = 0; i < m.length; i++) {
      const n = m[i];
      if (n.xclass) {
        return true;
      }

      if (n.children && n.children.length > 0) {
        if (existingLeaf(n.children) === true) {
          return true;
        }
      }
    }

    return false;
  };

  if (!menu) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      {menu.map((item) => {
        // The first level is always expandable
        if (!existingLeaf(item)) {
          return null;
        }

        return (
          <List
            key={`${item.menuid}`}
            subheader={
              <ListSubheader color="primary">
                {t(`menu.${item.text}`)}
              </ListSubheader>
            }
          >
            <Divider />
            {item.children
              ? item.children.map((menuItem) => (
                  <AppSidebarItem
                    key={`${item.menuid}-${menuItem.menuid}`}
                    item={menuItem}
                    level={0}
                    report={item.text === 'Menu'}
                  />
                ))
              : null}
          </List>
        );
      })}
    </Box>
  );
};

const AppSidebarUser: React.FC = () => {
  const { wallet, operatorname, moneta, account, email } = readLocalStorage();
  return (
    <Box
      sx={{
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar
        variant="circular"
        alt={operatorname}
        src={'/api/get/avatar.php?moneta=' + account || moneta}
      />
      <Typography variant="h6" component="div">
        {operatorname}
      </Typography>
      {wallet && (
        <Typography color="primary" component="div" variant="caption">
          {`0x..${wallet.substring(wallet.length - 18)}`}
        </Typography>
      )}
    </Box>
  );
};

const AppSidebarBottom: React.FC = () => {
  const { t } = useTranslation();

  const { status, mutateAsync: logout } = useLogout();

  const onLogout = () => {
    const lang = localStorage.getItem('language');
    localStorage.clear();
    closeAll();

    executePromise(logout(), {
      loading: 'status.logging_out',
      success: () => {
        window.location.reload();
        localStorage.setItem('language', lang);
        return null;
      },
    });
  };

  return (
    <Box
      sx={{
        p: 4,
      }}
    >
      <Button
        color="primary"
        onClick={onLogout}
        disabled={status === 'loading'}
        fullWidth
      >
        {t('menu.Logout')}
      </Button>
    </Box>
  );
};

const AppSidebar: React.FC = () => {
  const state = useSnapshot(appStore);
  const drawerState = state.modal.drawer;

  return (
    <Drawer anchor="left" open={drawerState} onClose={hideDrawer}>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: 'scroll' },
          overflowBehavior: {
            x: 'hidden',
            y: 'scroll',
          },
        }}
        style={{ height: '100%' }}
      >
        <AppSidebarUser />
        <AppSidebarItems />
        <Divider />
        <AppSidebarBottom />
      </OverlayScrollbarsComponent>
    </Drawer>
  );
};

export default AppSidebar;
