import { AppBar, Toolbar, Button, Container, Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import DeleteIcon from '@mui/icons-material/SignUp\\';
// import LoginIcon from '@mui/icons-material/Login';

import LanguageSelect from '../../components/Language/LanguageSelect';

const HomeLayout = ({ children }) => {
  const { classes } = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <AppBar position="fixed" color="transparent" className={classes.appBar}>
        <Container maxWidth="md">
          <Toolbar>
            <Link to="/">
              <img
                src="/assets/knowins-logo.svg"
                className={classes.logo}
                alt="Logo"
              />
            </Link>
            <Box
              sx={{
                flex: '1 1 auto',
              }}
            />
            <LanguageSelect />
            <Button
              color="primary"
              variant="outlined"
              className={classes.button}
              component={Link}
              to="login"
            >
              {t('login.title')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.button}
              component={Link}
              to="/sign-up"
            >
              {t('register.title_action')}
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
      {children}
    </>
  );
};

const useStyles = makeStyles()((theme) => ({
  appBar: {
    background: 'linear-gradient(90deg, #bfcad3 0%, #b7c1c9 100%)',
    borderBottom: '1px rgba(0, 0, 0, 0.04) solid',
  },
  logo: {
    width: theme.spacing(68),
    height: 58,
  },
  button: {
    marginLeft: theme.spacing(2),
    textTransform: 'none',
  },
}));

export default HomeLayout;
