import { useCallback, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { ethers } from 'ethers';
// import { UniversalProvider } from "@walletconnect/universal-provider";
import { AuthClient, generateNonce } from '@walletconnect/auth-client';
import SignClient from '@walletconnect/sign-client';
import { Core } from '@walletconnect/core';

import CONFIG from 'constant/common';
import { info, error, notice } from '@knowins/notifier';
import { getDidAddress, getNamespacedDidChainId } from '../utils/address';
import { verifySignature } from 'features/WalletConnect/utils/signature';
import { getError } from 'helper/error';
import store from '../store';

export const walletconnect = 'walletconnect';
export let authClient: any;
export let signClient: any;
export let provider: any;

export const metadata = {
  name: 'knowins',
  description: 'KNOWINS Knowledge Sharing Community',
  url: window.location.host,
  icons: [
    'https://knowins.com/assets/K.svg',
    'https://knowins.com/assets/K.png',
  ],
};

const methods = [
  'eth_sendTransaction',
  'eth_signTransaction',
  'eth_sign',
  'personal_sign',
  'eth_signTypedData',
];

const events = ['connect', 'disconnect']; //  "session_event", "session_update";

let assureRunOnlyOnce = false;
let count = 0;

const getSession = (session) => {
  const { expiry, namespaces, topic } = session;
  const walletAddress = namespaces.eip155.accounts[0];
  const iss = `did:pkh:${walletAddress}`;
  console.log('..........expiry', expiry);
  // Compose authenticate params
  const domain = window.location.hostname.split('.').slice(-2).join('.');
  return {
    p: {
      domain,
      version: 'fallback',
      nonce: 'na',
      aud: window.location.href,
      type: 'eip4361',
      statement:
        'Welcome to KNOWINS. Signing is the only way we can truly know that you are the owner of the wallet you are connecting. Signing is a safe, gas-less transaction that does not in any way give permission to perform any transactions with your wallet.',
      iat: new Date(expiry * 1000).toISOString(),
      iss,
    },
    s: {},
    topic,
    expiry,
  };
};

export const getAccount = () => getDidAddress(store.session?.p?.iss);

export async function createAuthClient({ version = 0 }) {
  const core = new Core({
    projectId: CONFIG.PROJECT_ID,
  });

  if (version === 0) {
    signClient = await SignClient.init({
      relayUrl: 'wss://relay.walletconnect.com',
      projectId: CONFIG.PROJECT_ID,
      // optional parameters
      //relayUrl: "<YOUR RELAY URL>",
      metadata,
    });
  } else if (version === 1) {
    authClient = await AuthClient.init({
      projectId: CONFIG.PROJECT_ID,
      metadata,
    });
    // web3wallet = await Web3Wallet.init({
    //   core, // <- pass the shared `core` instance
    //   metadata,
    // });
  } else if (provider) {
    // provider = await EthereumProvider.init({
    //   projectId: "1854555a752db30bba2d5b217e3bbd5d",
    //   chains: [1],
    //   // optionalChains, // OPTIONAL chains
    //   showQrModal: false, // REQUIRED set to "true" to use @walletconnect/modal
    //   methods, // REQUIRED ethereum methods
    //   // optionalMethods, // OPTIONAL ethereum methods
    //   events, // REQUIRED ethereum events
    //   // optionalEvents, // OPTIONAL ethereum events
    //   // rpcMap, // OPTIONAL rpc urls for each chain
    //   // metadata, // OPTIONAL metadata of your app
    //   // qrModalOptions, // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
    // });
    // provider = await UniversalProvider.init({
    //   projectId: "1854555a752db30bba2d5b217e3bbd5d",
    //   metadata,
    // });
  }
}

export async function onWalletConnect(args: any = undefined) {
  let version: number;
  if (args?.version !== undefined) {
    version = args.version;
    store.version = version;
  } else {
    // const state = useSnapshot(store);
    version = store.version;
  }

  if (!authClient && !signClient) {
    return info('action.wait');
  }

  const domain = window.location.hostname.split('.').slice(-2).join('.');
  const open = (uri: string) => {
    store.authorization = [];
    store.selected = '';
    store.uri = uri;
    store.open = true;
    store.args = args;
  };

  const proposalNamespace = {
    eip155: {
      methods,
      chains: [store.chainId],
      // chains: ['eip155:1', 'eip155:61', 'eip155:5'],
      events,
    },
  };

  if (provider) {
    provider.on('display_uri', (uri: string, approval) => {
      console.log('...............uri', uri, approval);
      // if (uri) {
      open(uri);
      // }
    });

    // provider.connect();
    const { uri, approval } = await provider.connect({
      namespaces: proposalNamespace,
      // {
      //   eip155: {
      //     methods,
      //     chains: [store.chainId],
      //     events,
      //     // rpcMap: {
      //     //   80001:
      //     //     "https://rpc.walletconnect.com?chainId=eip155:80001&projectId=<your walletconnect project id>",
      //     // },
      //   },
      //   // pairingTopic: "<123...topic>", // optional topic to connect to
      //   // skipPairing: false, // optional to skip pairing ( later it can be resumed by invoking .pair())
      // },
    });
    console.log('1...............uri', uri, approval);
    return {};
  } else if (signClient) {
    try {
      // abuse vivid foster uncle sand way buyer member injury fly violin raven -- very trivial: TestWallet@123

      // const { uri, approval } = await web3wallet.engine.signClient.connect({
      //   requiredNamespaces: proposalNamespace,
      // });
      const { uri, approval } = await signClient
        .connect({
          // Optionally: pass a known prior pairing (e.g. from `signClient.core.pairing.getPairings()`) to skip the `uri` step.
          // pairingTopic: pairing?.topic,
          // Provide the namespaces and chains (e.g. `eip155` for EVM-based chains) we want to use in this session.
          requiredNamespaces: proposalNamespace,
          // optionalNamespaces: {
          //   eip155: {
          //     methods,
          //     chains: [store.l1ChainId],
          //     events,
          //   },
          // },
        })
        .catch((e) => {
          console.log(e);
        });

      if (uri) {
        // wc:872194600639647a37a4f52df204f3321397a700b18d5048b164a27b8676e240@2?relay-protocol=irn&symKey=19de024358b85ddc6ace3afac710652dd5d26080ac009cae47467626bb220dc0
        open(uri);
      }

      setTimeout(async () => {
        try {
          const session = await approval();
          console.log('session', session);
          store.session = getSession(session);
        } catch (e: any) {
          console.log(e);
          // reset();
          // return {
          //   success: false,
          //   message: e.message,
          // };
        }
      }, 1000);

      return { uri, approval };
    } catch (e) {
      console.log('Error: %s', e);
    }
  } else if (version === 1) {
    // const { uri } = await web3wallet.engine.authClient.request({
    const { uri } = await authClient.request({
      aud: window.location.href,
      domain,
      chains: [store.chainId],
      // chains: ['eip155:1', 'eip155:61', 'eip155:5'],
      nonce: generateNonce(),
      // type: 'eip4361',
      statement: 'Sign in with wallet',
      // methods: ['wc_authRequest'], ???
    });

    if (uri) {
      // wc:872194600639647a37a4f52df204f3321397a700b18d5048b164a27b8676e240@2?relay-protocol=irn&symKey=19de024358b85ddc6ace3afac710652dd5d26080ac009cae47467626bb220dc0
      open(uri);
    }

    return { uri };
  }
}

export const subscribeToEvents = async (version = 0, connect) => {
  if (!authClient && !signClient) {
    throw Error('Unable to subscribe to events. Client does not exist.');
  }

  try {
    if (provider) {
      provider.on('session_event', (r, a) => {
        console.log('on session_event........', r, a);
      });

      provider.on('accountsChanged', async (accounts, a) => {
        const account = accounts[0];
        if (store.selected != account) {
          store.selected = account;
          console.log('do something...........');
          // const walletAddress = account;
          // const iss = `${store.chainId}:did:pkh:${walletAddress}`;
          // const domain = window.location.hostname.split(".").slice(-2).join(".");

          // store.session = {
          //   p: {
          //     domain,
          //     version: "1",
          //     nonce: "na",
          //     aud: window.location.href,
          //     type: "eip4361",
          //     statement:
          //       "Welcome to KNOWINS. Signing is the only way we can truly know that you are the owner of the wallet you are connecting. Signing is a safe, gas-less transaction that does not in any way give permission to perform any transactions with your wallet.",
          //     iat: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(),
          //     iss,
          //   },
          //   s: {},
          //   topic: "walletconnect",
          // };

          // const result = await connect(store.session, store.args);
        }
      });

      provider.on('connect', (payload, a) => {
        console.log('on connect........', payload);
        store.session = getSession(payload.session);
      });

      provider.on('disconnect', (r, a) => {
        console.log('on disconnect........', r, a);
      });
    } else if (signClient) {
      signClient.on('connect', async (res) => {
        console.log('connect...........:', res);
      });

      signClient.on('session_event', async (res) => {
        console.log('session_event...........:', res);
      });

      signClient.on('session_proposal', async (res) => {
        console.log('session_proposal...........:', res);
      });

      signClient.on('session_delete', () => {
        console.log('The user has disconnected the session from their wallet.');
      });
    } else if (authClient) {
      // web3wallet.engine.authClient.on('auth_response', async (res) => {
      authClient.on('auth_response', async (res) => {
        const { params } = res;
        console.error(res);
        if ('code' in params || 'error' in params) {
          if (params.error?.code == 5000) {
            store.open = false;
            return notice('status.user_rejected');
          } else if (params.error?.message) {
            // Let's fallback and try again
            await createAuthClient({ version: 0 });

            subscribeToEvents(0, connect);

            notice('status.wallet_connect_error');
            return await onWalletConnect({ version: 0 });
          }
          return notice('status.something_wrong');
        }

        const { s: signature, p: payload } = params.result;
        const walletAddress = getDidAddress(payload.iss);
        const chainId = getNamespacedDidChainId(payload.iss);
        const reconstructed = authClient.formatMessage(payload, payload.iss);
        console.log('reconstructed: %s', reconstructed);

        if (!walletAddress) {
          console.log('Could not derive address from `payload.iss`');
          return notice('status.wc_payload_address_error');
        }
        if (!chainId) {
          console.log('Could not derive chainId from `payload.iss`');
          return notice('status.wc_payload_chain_error');
        }

        const isValid = await verifySignature(
          walletAddress,
          reconstructed,
          signature,
          chainId,
          CONFIG.PROJECT_ID
        );

        if (!isValid) {
          return notice('status.wc_invalid_singature');
        }

        store.session = params.result;
      });
    }
  } catch (e: any) {
    console.log(e);
    error(e);
  }
};

export function useWalletConnect({ version, connect, delegation = false }) {
  const state = useSnapshot(store);
  const { session, selected, args } = state;

  const onInitialize = useCallback(async () => {
    try {
      if (!assureRunOnlyOnce) {
        assureRunOnlyOnce = true;
        if (!authClient && !signClient) {
          await createAuthClient({ version });
        }
        store.version = version;

        console.log('initialize auth client:', ++count);
        // console.log('authClient', authClient);

        subscribeToEvents(version, connect);
      }
    } catch (err: any) {
      console.log(err);
      error(err);
    }
  }, []);

  const onSession = useCallback(
    async (session) => {
      try {
        if (session) {
          console.log('session topic', session.topic);
          const result = await connect(session, args);
          // console.log('connect...........', result);
          if (!delegation) {
            store.open = false;
          } else {
            if (Array.isArray(result) && result.length > 0) {
              console.log('Found delegations:', result);
              store.authorization = result;
            } else {
              store.open = false;
            }
          }
        }
      } catch (err: any) {
        console.log(err);
        error(err);
      }
    },
    [args]
  );

  useEffect(() => {
    onInitialize();
  }, []);

  useEffect(() => {
    if (selected.length > 0) {
      connect({ ...state.session, username: selected });
    }
  }, [selected]);

  useEffect(() => {
    onSession(session);
  }, [session]);
}

export async function request(
  from: string,
  to: string,
  data: string,
  amount: string,
  gasPrice: string,
  estimatedGas: string,
  EIP712Msg: string,
  chainId: number,
  fromAddr: string,
  type: number,
  nonce: number,
  customData: any,
  gas: string | number,
  gasLimit: string | number,
  maxFeePerGas: string | number,
  maxPriorityFeePerGas: string | number,
  setProgress?: (any?: any) => void
) {
  if (!signClient && !provider) {
    throw Error('Unable to send payment request');
  }

  try {
    const topic = store.session.topic;
    console.log('topic........', topic);
    if (!EIP712Msg) {
      let tx;
      if (!customData) {
        console.log('legacy transaction');
        tx = {
          from,
          to,
          data,
          // nonce,
          gasLimit: estimatedGas || gasLimit || maxFeePerGas,
          gasPrice: gasPrice || gas,
          value: amount,
          chainId,
        };

        if (type) {
          tx.type = type.toString();
        }
      } else {
        console.log('paymaster transaction');
        tx = {
          from,
          to,
          // gasPrice: gasPrice || gas,
          // gas: estimatedGas || gasLimit, // When promote, Wallet Connect throw invalid key
          gasLimit: estimatedGas || gasLimit,
          value: amount,
          data,
          nonce: ethers.utils.hexValue(nonce),
          customData,
          maxFeePerGas,
          maxPriorityFeePerGas,
          // chainId,
        };
      }
      console.log('tx........', tx);

      if (provider) {
        const res = await provider.request(
          {
            method: 'eth_sendTransaction',
            params: [tx],
          },
          `eip155:${chainId}`
        );
        console.log(res);
        return {
          success: true,
          transaction_hash: res,
        };
      } else if (signClient) {
        // const txHash = await signClient.request({
        //   topic,
        //   chainId: store.chainId,
        //   request: {
        //     method: 'eth_sendTransaction',
        //     params: [tx],
        //   },
        // });
        console.log('topic........', topic);
        console.log('tx........', tx);

        if (chainId == 1 || chainId == 5) {
          console.log('ensure chain id');
          // await signClient.request({
          //   topic,
          //   chainId: `eip155:${chainId}`, // "eip155:324", // store.chainId,
          //   request: {
          //     method: "wallet_switchEthereumChain",
          //     // params: [{ chainId: ethers.utils.hexValue(chainId) }],
          //     params: [{ chainId: `0x${chainId}` }],
          //   },
          // });

          await signClient
            .connect({
              requiredNamespaces: {
                eip155: {
                  methods,
                  chains: [`eip155:${chainId}`],
                  events,
                },
              },
            })
            .catch((e) => {
              console.log(e);
            });
        }

        if (typeof setProgress === 'function') {
          setProgress();
        }

        const req = {
          topic,
          chainId: store.chainId,
          // chainId: `eip155:${chainId}`, // "eip155:324", // store.chainId,
          request: {
            // method: "eth_signTransaction",
            // id: 1,
            // jsonrpc: "2.0",
            method: 'eth_sendTransaction',
            params: [tx],
          },
        };

        console.log('tx........', tx);
        const txHash = await signClient.request(req);

        console.log('txHash........', txHash);
        return {
          success: true,
          transaction_hash: txHash,
        };
      }
    }
  } catch (e: any) {
    if (typeof setProgress === 'function') {
      setProgress('NONE');
    }

    if (e && e.code) {
      console.log(e);
      switch (e.code) {
        case -32000:
          return {
            success: false,
            message: 'status.wc_payment_rejected',
          };
        default:
      }
    }
    return {
      success: false,
      message: getError(e) || 'status.something_wrong',
    };
  } finally {
    store.open = false;
  }
}
