import axios from 'redaxios';
import appStore from 'store/appStore';
import CONFIG, { CRUD } from 'constant/common';

export * from './types';
export * from './proxy';
export * from './repository';

export const paynow = async () => {};

export const deposit = async () => {};

export const donate = async () => {};

export async function getPrintSetup(data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmPrint',
      method: 'get',
      data: [data],
    })
    .then((res) => res.data.result.data);
}

export async function reverseJournal(data = { selectedJournalNo: -1 }) {
  return axios
    .post('/api/router.php', {
      action: 'FrmLaJournalEntry',
      method: 'reverse',
      data: [data],
    })
    .then((res) => {
      console.log(res.data);
      return res.data.result.data || res.data.result;
    });
}
