import CONFIG from 'constant/common';
import { ChatClient, IChatClient } from '@walletconnect/chat-client';
import { io } from 'socket.io-client';

export let chatClient: IChatClient | any;
export let chatPublicClient: IChatClient;

//let count = 0;
// FIXME: update relayUrl here to not hardcode local relay.
export async function createChatClient({ version = 0 }) {
  if (version >= 0) {
    // chatClient = await ChatClient.init({
    //   logger: 'debug',
    //   projectId: CONFIG.PROJECT_ID,
    //   relayUrl: 'wss://relay.walletconnect.com',
    //   // relayUrl: 'ws://dev.communication.knowins.com',
    //   // process.env.PUBLIC_RELAY_URL ?? 'wss://relay.walletconnect.com'
    // });
  } else if (version === -1) {
    // Browser clients use the native WebSocket object
    // const ws = new WebSocket(process.env.WS_ENDPOINT || 'ws://localhost:8083/');
    const ws = new WebSocket(
      process.env.WS_ENDPOINT || 'wss://relay.walletconnect.com'
    );

    const initialize = new Promise((resolve, reject) => {
      ws.addEventListener('open', (args) => {
        console.log('ws connected', args);

        const t = setTimeout(() => {
          reject();
        }, 30000);

        ws.addEventListener('message', (data) => {
          console.log('received: %s', data);
        });

        clearTimeout(t);
        resolve(args);
      });
    });

    await initialize.then((res) => {
      chatClient = {
        // website_socket_id:
        register: async ({ account = '' }) => {
          // ws.send(Buffer.from(JSON.stringify({ account })));
          ws.send('hello world');
          return 'heloo';
        },
        on: (evt, fn) => {},
        once: () => {},
        chatThreads: {
          getAll: () => {
            return [];
          },
        },
        chatInvites: {
          getAll: () => {
            return [];
          },
        },
        chatMessages: {
          getAll: () => {
            return [];
          },
        },
      };
    });
  } else if (version === -2) {
    const handlers = {};

    const socket = io(
      process.env.REACT_APP_WS_SERVER_ENDPOINT || 'https://communication.knowins.com/',
      { forceNew: true }
    );

    const email = localStorage.getItem('email');
    const username = localStorage.getItem('moneta');
    const language = localStorage.getItem('language');
    const session_id = localStorage.getItem('ws_session_id') || '';

    if (session_id) {
      socket.auth = { session_id, email, username, language };
      socket.connect();
    }

    // socket.on('connect_error', (err) => {
    //   if (count < 100) {
    //     count++;
    //   } else {
    //     console.log('Disconnect web socket');
    //     count = 0;
    //     socket..()
    //   }
    //   console.log(err);
    // });

    socket.on('statistic', (data) => {
      console.log('ws statistic', data);
    });

    socket.on('chat_invite', (data) => {
      if (typeof handlers['chat_invite'] === 'function') {
        handlers['chat_invite'](data);
      } else {
        console.error('chat_invite handler NOT found');
      }
    });

    socket.on('chat_joined', (data) => {
      if (typeof handlers['chat_joined'] === 'function') {
        handlers['chat_joined'](data);
      } else {
        console.error('chat_joined handler NOT found');
      }
    });

    socket.on('chat_message', (data) => {
      if (typeof handlers['chat_message'] === 'function') {
        handlers['chat_message'](data);
      } else {
        console.error('chat_message handler NOT found');
      }
    });

    const initialize = new Promise((resolve, reject) => {
      // TODO: Enfore authentication later on the backend
      socket.auth = {
        username,
        email,
        language,
        session_id,
        // jwt:...
      };

      socket.on('connect', () => {
        console.log('ws connected');

        const t = setTimeout(() => {
          reject();
        }, 30000);

        socket.on('session', ({ session_id, id }) => {
          console.log('session starts %s', session_id, id);
          // Attach the session ID to the next reconnection attempts
          socket.auth = { session_id };
          localStorage.setItem('ws_session_id', session_id);

          clearTimeout(t);
          resolve(id);
        });
      });
    });

    await initialize.then((id) => {
      chatClient = {
        ws_connection_id: id,
        register: async ({ account = '' }) => {
          socket.emit('register', {
            account,
            website_socket_id: socket.id,
          });
          return id;
        },
        invite: async (params: {
          account: string;
          invite: {
            signature: string;
            account: string;
            message: string;
          };
        }) => {
          // Create unique topic ID
          const initialTopicSearchKey = Date.now();
          try {
            socket.emit('private', params);
            return initialTopicSearchKey; // id;
          } catch (e: any) {
            console.warn(e?.message);
            throw 'status.ws_emit_failure';
          }
        },
        accept: async (params: { id: number }) => {
          try {
            socket.emit('chat_accepted', params);
            return params.id;
          } catch (e: any) {
            console.warn(e?.message);
            throw 'status.ws_emit_failure';
          }
        },
        message: async ({ topic, payload }) => {
          try {
            socket.emit('chat_message', { topic, payload });
            return { id, topic };
          } catch (e: any) {
            console.warn(e?.message);
            throw 'status.ws_emit_failure';
          }
        },
        on: (evt: string, fn: (arg0: any) => any) => {
          switch (evt) {
            case 'chat_invite':
              handlers['chat_invite'] = fn;
              break;
            case 'chat_joined':
              handlers['chat_joined'] = fn;
              break;
            case 'chat_message':
              handlers['chat_message'] = fn;
              break;

            default:
          }
        },
        once: () => {},
        chatThreads: {
          getAll: () => {
            return [];
          },
        },
        chatInvites: {
          getAll: () => {
            return [];
          },
        },
        chatMessages: {
          getAll: () => {
            return [];
          },
        },
      };
    });
  }
}
