import { proxy, useSnapshot } from 'valtio';

const state = proxy({
  open: false,
  session: 'private',
  public: [],
  team: [],
  current: '',
  private: {
    // vietle: {
    //   accepted: true,
    //   email: 'lecaoviet@gmail.com',
    //   topic: '1',
    //   adddress: '',
    //   payloads: [
    //     { timestamp: '1', message: 'hello', incoming: true },
    //     { timestamp: '2', message: 'Hi', incoming: true },
    //     { timestamp: '3', message: 'How are you?', incoming: false },
    //   ],
    // },
  },
});

export const getChat = (address = '') => {
  // chatter's address can be an email or user's account name
  if (address.indexOf('@') === -1) {
    return Object.keys(state.private).includes(address)? address : null;
  }

  for (const props in state.private) {
    if (state.private[props].email === address) {
      return props;
    }
  }
  return null;
};

export default state;
