import { useCallback, useEffect, useState } from 'react';
import { proxy, useSnapshot } from 'valtio';
import { ChatClientTypes } from '@walletconnect/chat-client';

import { chatClient, createChatClient } from '../utils';
import { confirm, notice } from '@knowins/notifier';

import store from 'features/Chat/store/ChatStore';

let assureRunOnlyOnce = false;
let count = 0;

export default function useInitialization(eip155Addresses) {
  const [initialized, setInitialized] = useState(false);
  // const state = useSnapshot(store);

  const onChatInvite = useCallback(
    (
      id = 0,
      givenTopic: string,
      params: { account: string; message: string; signature?: string }
    ) => {
      //console.log('onChatInvite .topic/id.........', topic, id);
      const { signature, message, account } = params;
      confirm(
        `[${signature}: ${message}] Chat?`,
        async () => {
          const [ok, topic_id] = await accept(id);
          if (ok === true) {
            console.log(
              'onChatInvite accepted with topic id.........',
              topic_id
            );
            if (id !== topic_id) {
              console.warn('DIFFERENT TOPIC ID........');
            }
            if (signature) {
              const [current, fullname] = signature.split('|');
              store.session = 'private';
              store.current = current;
              store.private[current] = {
                // id,
                accepted: true,
                //email,
                fullname,
                topic_id,
                topic: givenTopic, // IMPORTANT: Update a new topic returned by accepted an invite, ignore topic of the invitor
                adddress: account,
                payloads: [
                  { timestamp: Date.now(), message, authorAccount: account },
                ],
              };
              store.open = true;
            }
          } else {
            if (topic_id.indexOf('No matching key') !== -1) {
              // await chatClient.reject({ id });
              notice('status.chat_left');
            } else {
              notice('status.something_wrong');
              console.log('Chat accept error', topic_id);
            }
          }
        },
        async () => {
          try {
            await chatClient.reject({ id });
          } catch (e: any) {
            console.log('Chat reject error', e?.message);
          }
        }
      );
    },
    []
  );

  const onChatJoined = useCallback(
    (id: string, topic: string, addr: string) => {
      console.log('onChatJoined topic = %s', topic);
      Object.keys(store.private).map((chat) => {
        const current = store.private[chat];
        console.log(chat, current);
        if (current.adddress === addr) {
          store.private[chat].topic = topic; // topic of the invitee (person who accepts chat)
          store.private[chat].topic_id = id; // topic id of the invitee (person who accepts chat)
          store.private[chat].accepted = true;
          store.current = chat;
          store.open = true;
        }
      });
    },
    []
  );

  const onChatMessage = useCallback(
    (
      id: number,
      params: { authorAccount: string; message: string; timestamp: number },
      topic: string
    ) => {
      Object.keys(store.private).map((chat) => {
        if (store.private[chat].topic === topic) {
          // store.private[chat].topic = topic; // Unchanged topic
          store.private[chat].payloads = [
            ...store.private[chat].payloads,
            params,
          ];
        }
      });
    },
    []
  );

  const onInitialize = useCallback(async (addresses: string) => {
    try {
      if (initialized === false && !assureRunOnlyOnce) {
        assureRunOnlyOnce = true;
        if (!chatClient) {
          await createChatClient({ version: -2 });
        }

        console.log(
          'initialized chat wallet:',
          ++count,
          initialized,
          eip155Addresses
        );
        try {
          await chatClient
            .register({
              account: `eip155:1:${addresses}`,
            })
            .then((res) => {
              console.log('register result', res);
            })
            .catch((e) => {
              console.log('register exception:', e);
            });
        } catch (e) {
          console.log('chatClient register error:', e);
        }

        console.log(
          '[Chat] registered address %s on keyserver',
          `eip155:1:${addresses}`
        );

        console.log('chatThreads on load:', chatClient.chatThreads.getAll());
        console.log(localStorage.getItem('wallet'));
        const chatThreads = chatClient.chatThreads.getAll();
        chatThreads.map(async ({ topic }) => await leave(topic));

        console.log('chatInvites on load:', chatClient.chatInvites.getAll());
        const chatInvites = chatClient.chatInvites.getAll();
        chatInvites.map(({ id = 0, message, account, signature }) =>
          onChatInvite(id, '', { message, account, signature })
        );

        console.log('chatMessages on load:', chatClient.chatMessages.getAll());

        chatClient.on('chat_invite', async (invite) => {
          console.log('chat_invite......', invite);
          const { id, params, topic } = invite;
          onChatInvite(id, topic, params);
        });

        chatClient.on('chat_joined', async (event) => {
          console.log('chat_joined:', event);
          const { id, account, topic } = event;

          //console.log(chatClient.getMessages({ topic }))
          onChatJoined(id, topic, account);
        });

        chatClient.on('chat_message', (event) => {
          // React to an incoming messages for a given chat.
          console.log('chat_message:', event);
          const { id, params, topic } = event;
          onChatMessage(id, params, topic);
        });

        // if (addresses !== '0xbf26fe70cba74be8a3cd59a40d3964f737722a74') {
        //   const invite: ChatClientTypes.PartialInvite = {
        //     signature: localStorage.getItem('moneta') || '',
        //     message: 'Hey. How are you?',
        //     account: `eip155:1:0xaA19C53b396342573A824D52B3A07aa7603b8070`,
        //   };
        //   const targetAddress = '0xbf26fe70cba74be8a3cd59a40d3964f737722a74';
        //   try {
        //     await chatClient.invite({
        //       //account: `eip155:1:${publicChatAddress}`,
        //       account: `eip155:1:${targetAddress}`,
        //       invite,
        //     });
        //   } catch (e) {
        //     console.log('Error invite public chat ', e);
        //   }
        // }
        setInitialized(true);
        // assureRunOnlyOnce = false;
      }
    } catch (err: unknown) {
      alert(err);
    }
  }, []);

  useEffect(() => {
    onInitialize(eip155Addresses);
  }, [initialized, onInitialize]);

  return initialized;
}

export async function invite(
  targetAddress: string,
  fullname: string,
  message: string
) {
  const sender = localStorage.getItem('moneta') || '';
  const senderWallet = localStorage.getItem('wallet');

  const invitation = {
    signature: `${sender}|${fullname}`,
    account: `eip155:1:${senderWallet}`,
    message,
  };

  try {
    const res = await chatClient.invite({
      account: `eip155:1:${targetAddress}`,
      invite: invitation,
    });
    console.log('invite res:......', res);
    return res;
  } catch (e: any) {
    console.log('Chat invite error', e?.message);
    return false;
  }
}

export async function accept(id: number) {
  try {
    const topic_id = await chatClient.accept({ id });
    console.log('message accept got topic:......', topic_id);
    return [true, topic_id];
  } catch (e: any) {
    console.log('Chat accept error', e?.message);
    return [false, e?.message];
  }
}

export async function text(topic: string, message: string) {
  const senderWallet = localStorage.getItem('wallet');
  try {
    const res = await chatClient.message({
      topic,
      payload: {
        message,
        authorAccount: `eip155:1:${senderWallet}`,
        timestamp: Date.now(),
      },
    });
    console.log('message res:......', res);
    return res;
  } catch (e: any) {
    console.log('Chat text error', e?.message);
    return false;
  }
}

export async function leave(topic: string) {
  console.log('topic leave:......', topic);
  try {
    const res = await chatClient.leave({ topic });
    console.log('message leave:......', res);
    return res;
  } catch (e: any) {
    console.log('Chat leave error', e?.message);
    return false;
  }
}
