import { useState, useEffect } from 'react';
import { Container, Button, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import axios from 'redaxios';

import { executePromise } from '@knowins/notifier';

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [confirm, setConfirm] = useState(false);
  const [status, setStatus] = useState('');
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (data: { email: string }) => {
    const { email } = data;
    setStatus('loading');

    executePromise(
      axios.post(`/authenticate/api/password/forgot/${email}`).then((res) => {
        setConfirm(true);
        setStatus('');
        return res;
      }),
      {
        loading: 'status.sending_request',
        success: () => null,
        error: null,
      }
    );
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <img
        src="/assets/knowins-slogan.svg"
        className={classes.slogan}
        alt="who knows win"
      />
      <Typography style={{ marginBottom: 33 }} align="center" variant="h6">
        {t('login.reset_password')}
      </Typography>
      {confirm === false ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                required
                fullWidth
                type="email"
                label={t('user.email_address')}
                variant="standard"
              />
            )}
          />
          <Button
            sx={{ m: '1rem 0 0 0' }}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={status === 'loading'}
          >
            {t('login.confirm')}
          </Button>
        </form>
      ) : (
        <div>
          <Typography>{t('login.reset_pass_notification')}</Typography>
          <br />
          <Typography
            style={{ marginBottom: 0 }}
            align="center"
            color="textSecondary"
            variant="subtitle1"
            component="p"
            paragraph
          >
            {t('login.trouble_logging')}{' '}
            <Link component={RouterLink} to="/support">
              {t('login.contact_support')}
            </Link>
          </Typography>
        </div>
      )}
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(12, 4),
    textAlign: 'center',
  },
  slogan: {
    width: theme.spacing(88),
  },
}));

export default ForgotPassword;
