import { useEffect, useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import axios from 'redaxios';
import { TextField, Typography, Container, Link } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LoadingButton from '@mui/lab/LoadingButton';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInboxSharp';
import SendIcon from '@mui/icons-material/SendSharp';
import ReactInputVerificationCode from 'react-input-verification-code';

import { CountrySelect } from 'components/Form';
import { executePromise } from '@knowins/notifier';
import './Register.css';

import bcrypt from 'bcryptjs-react';

const Register: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const navigate = useNavigate();
  // const { mutateAsync } = useAddUserMutation();
  const [confirm, setConfirm] = useState('');
  const [email, setEmail] = useState('');
  const [syncing, setSyncing] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      username: '',
      password: '',
      countryid: '00',
    },
  });

  const onSubmit = async (data: any) => {
    setSyncing(true);
    const { username, password, ...others } = data;
    const salt = bcrypt.genSaltSync(10);
    const encryptedpassword = bcrypt.hashSync(password, salt);

    executePromise(
      axios.post(`/authenticate/api/user/add`, {
        ...others,
        username: username.toLowerCase(),
        email: email.toLowerCase(),
        password: encryptedpassword,
        token: confirm,
      }),
      {
        loading: 'status.processing',
        success: () => {
          navigate('/login');
          return null;
        },
        error: (err) => {
          setSyncing(false);
          return err;
        },
      }
    );
  };

  const startVerify = () => {
    setSyncing(true);
    // Send verification email
    executePromise(
      axios
        .post(`/authenticate/api/user/register/${email}`)
        .then((res) => {
          setSyncing(false);
          if (res?.data?.success) {
            setConfirm('checking');
          }
          return res;
        })
        .catch((e) => {
          setSyncing(false);
        }),
      {
        loading: 'status.verifying',
        success: null,
      }
    );
  };

  const verify = (token: string) => {
    executePromise(
      axios
        .post(`/authenticate/api/user/verify-email`, {
          email,
          token,
        })
        .then((res) => {
          if (res?.data?.success) {
            setConfirm(token);
          }
          return res;
        }),
      {
        loading: null,
        success: null,
      }
    );
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <img
        src="/assets/knowins-slogan.svg"
        className={classes.slogan}
        alt="who knows wins"
      />
      <Typography align="center" variant="h4" component="h1">
        {t('register.title')}
      </Typography>
      <Typography
        align="center"
        color="textSecondary"
        variant="subtitle1"
        component="p"
        paragraph
      >
        {t('register.has_account')}{' '}
        <Link component={RouterLink} to="/login">
          {t('login.title')}
        </Link>
      </Typography>
      <TextField
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={confirm !== ''}
        margin="dense"
        required
        fullWidth
        type="email"
        label={t('user.email')}
        variant="standard"
        size="small"
      />
      {confirm === '' && (
        <LoadingButton
          sx={{ m: '1rem 0 0 0' }}
          fullWidth
          variant="contained"
          loading={syncing}
          disabled={syncing}
          loadingPosition="start"
          startIcon={<ForwardToInboxIcon />}
          onClick={startVerify}
        >
          {t('register.verify')}
        </LoadingButton>
      )}
      {confirm === 'checking' ? (
        <>
          <div
            className="custom-styles"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography style={{ textAlign: 'left' }}>
              {t('register.confirm')}
            </Typography>
            <ReactInputVerificationCode
              placeholder=""
              length={5}
              //title={t('login.enter_code_received_in_email')}
              onCompleted={verify}
            />
          </div>
        </>
      ) : confirm === '' ? null : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                required
                fullWidth
                label={t('user.fullname')}
                variant="standard"
                size="small"
              />
            )}
          />
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                required
                fullWidth
                label={t('user.account_name')}
                variant="standard"
                size="small"
                inputProps={{ style: { textTransform: 'lowercase' } }}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ minLength: 6 }}
            render={({ field }) => (
              <TextField
                {...field}
                margin="dense"
                required
                fullWidth
                label={t('user.password')}
                type="password"
                autoComplete="new-password"
                variant="standard"
                size="small"
                error={errors?.password?.type === 'minLength'}
                helperText={
                  errors?.password?.type === 'minLength'
                    ? t('status.minimum_6_chars')
                    : ''
                }
              />
            )}
          />
          <Controller
            name="countryid"
            control={control}
            render={({ field }) => (
              <CountrySelect
                {...field}
                required
                label=""
                selectProps={{}}
                // size="small"
              />
            )}
          />
          <LoadingButton
            sx={{ m: '1rem 0 0 0' }}
            fullWidth
            variant="contained"
            loading={syncing}
            disabled={syncing}
            loadingPosition="start"
            startIcon={<SendIcon />}
            type="submit"
          >
            {t('register.title_action')}
          </LoadingButton>
        </form>
      )}
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    textAlign: 'center',
  },
  slogan: {
    width: theme.spacing(88),
  },
}));

export default Register;
