import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import axios from 'redaxios';
import {
  TextField,
  Container,
  Button,
  Typography,
  TextareaAutosize,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

import { error, executePromise } from '@knowins/notifier';
import { readLocalStorage } from 'helper/utils';

const Support = (props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [confirm, setConfirm] = useState(0);
  const { email: savedEmail = '' } = readLocalStorage();

  const [email, setEmail] = useState(savedEmail || '');

  // useEffect(() => {
  //   setEmail(savedEmail);
  // }, []);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email_address: email,
      subject: '',
      description: '',
    },
  });

  const onSubmit = async (data: {
    email_address: string;
    description: string;
  }) => {
    const { email_address, description } = data;
    if (!email_address || !description) {
      return error('status.missing_info');
    }

    executePromise(
      axios
        .post(`/support/api/general/`, {
          from: email_address,
          content: description,
        })
        .then((resp) => {
          if (resp?.data?.success) {
            setConfirm(1);
          }
          return resp;
        }),
      {
        loading: 'status.sending_request',
        success: 'status.success',
      }
    );
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="subtitle1">{t('support.recommendation')}</Typography>
      <br />
      <Typography
        style={{ marginBottom: 33 }}
        align="center"
        variant="h6"
        color="primary"
      >
        {t('support.title')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {confirm === 0 ? (
          <div>
            <Controller
              name="email_address"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  size="small"
                  required
                  fullWidth
                  label={t('user.email_address')}
                  variant="standard"
                  type="email"
                  disabled={!!email}
                />
              )}
            />
            <Controller
              name="subject"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  size="small"
                  rows={5}
                  required
                  fullWidth
                  label={t('support.subject')}
                  variant="standard"
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextareaAutosize
                  {...field}
                  required
                  minRows={10}
                  maxRows={10}
                  style={{ width: 576, marginTop: 23 }}
                  placeholder={t('support.description')}
                />
              )}
            />
            <Button
              sx={{ m: '1rem 0 0 0' }}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={status === 'loading'}
            >
              {t('action.send')}
            </Button>
          </div>
        ) : (
          <Typography>{t('support.notification')}</Typography>
        )}
      </form>
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(12, 4),
    textAlign: 'center',
  },
}));

export default Support;
