import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'redaxios';
import { RouterAPIData } from 'features/common/types';
import { updateLocalStorage, readLocalStorage } from 'helper/utils';
import {
  GetConfigData,
  GetConfigVariables,
  LaChartAccount,
  DbTemplateData,
  AddBidData,
  AddBidVariables,
  VerifyPaymentVariables,
  GetTransactionsData,
} from './types';
import { checkSessionTimeout } from './proxy';

import wcStore from 'features/WalletConnect/store';
import mmStore from 'features/MetaMask/store';

// Since FrmUtils data does not (often) change
// there is no need for auth query
const queryConfig = {
  refetchOnMount: false,
  staleTime: Infinity,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

const getKey = (
  action: string,
  method: string,
  data?: any // { [key: string]: string }
) => {
  const keys = [action, method];
  if (data) {
    for (const k in data) {
      keys.push(k);
      keys.push(
        data[k] !== null && data[k] !== undefined
          ? data[k].toString()
          : 'undefined'
      );
    }
  }
  return keys;
};

const ConnectDB = 'ConnectDB';
const getConfigKey = getKey(ConnectDB, 'getConfig');

export function useWallet() {
  const client = useQueryClient();
  return useMutation((variables) =>
    axios
      .post<RouterAPIData<GetConfigData>>('/api/router.php', {
        action: ConnectDB,
        method: 'connectWallet',
        data: [variables],
        type: 'rpc',
      })
      .then((res) => {
        if (res.data.result?.success)
          if (res.data.result.message !== 'action.select_account') {
            const data = res.data.result.data;
            if (data) {
              updateLocalStorage(data);
            }

            client.setQueryData<GetConfigData>(getConfigKey, data);
          }
        return res.data.result;
      })
  );
}

export function useLogin() {
  const client = useQueryClient();

  return useMutation<
    RouterAPIData<GetConfigData>['result'],
    unknown,
    GetConfigVariables
  >((variables) =>
    axios
      .post<RouterAPIData<GetConfigData>>('/api/router.php', {
        action: ConnectDB,
        data: [{ id: 1, ...variables }],
        method: 'getConfig',
        type: 'rpc',
      })
      .then((res) => {
        if (res.data.result?.success) {
          const data = res.data.result.data;
          if (data) {
            updateLocalStorage(data);
          }

          client.setQueryData<GetConfigData>(getConfigKey, data);
        }

        return res.data.result;
      })
  );
}

export function useLogout() {
  const client = useQueryClient();

  return useMutation<RouterAPIData<GetConfigData>['result'], unknown>(() =>
    axios
      .post<RouterAPIData<GetConfigData>>('/api/router.php', {
        action: ConnectDB,
        data: [{ id: 2 }],
        method: 'getConfig',
        type: 'rpc',
      })
      .then((res) => {
        if (res.data.result?.success) {
          client.setQueryData<GetConfigData>(
            getConfigKey,
            res.data.result.data
          );
        }
        return res.data.result;
      })
  );
}

export function useLoginStatus(count?: number) {
  // const client = useQueryClient();
  // let t: string | number | NodeJS.Timeout | undefined;

  return useQuery<GetConfigData>(getConfigKey, () => {
    const config = readLocalStorage();
    const { login, chainid } = config;
    if (!login) {
      return { login: false, failure: 0 };
    } else {
      if (chainid) {
        wcStore.chainId = chainid;
        mmStore.chainId = chainid;
      }
      return config;
    }
  });
}

export function useGetAccountQuery(data = {}) {
  const action = 'FrmLaChartAccount';
  return useQuery<LaChartAccount[]>(
    getKey(action, 'get', data),
    () =>
      axios
        .post<RouterAPIData<LaChartAccount[]>>('/api/router.php', {
          action,
          method: 'get',
          data: [data],
        })
        .then(checkSessionTimeout)
        .then((res) => res.data.result.data),
    queryConfig
  );
}

const FrmBid = 'FrmBid';
export function useAddBidMutation() {
  return useMutation<
    AddBidData & { success: boolean },
    unknown,
    AddBidVariables
  >(async (variables) => {
    return axios
      .post<{ result: AddBidData & { success: boolean } }>('/api/router.php', {
        action: FrmBid,
        method: 'addBid',
        data: [variables],
      })
      .then(checkSessionTimeout)
      .then((res) => res.data.result);
  });
}

export function useVerifyPaymentQuery(
  variables: VerifyPaymentVariables,
  done?: boolean
) {
  return useQuery(
    getKey(FrmBid, 'verifyPayment', variables), // [action, 'verifyPayment', variables],
    async () => {
      return axios
        .post<RouterAPIData>('/api/router.php', {
          action: FrmBid,
          method: 'verifyPayment',
          data: [variables],
          type: 'rpc',
          tid: 6,
        })
        .then(checkSessionTimeout)
        .then((res) => res.data.result);
    },
    !done ? { refetchInterval: 60 * 1000 } : undefined
  );
}

export function useTransactionQuery() {
  return useQuery<GetTransactionsData[]>(
    getKey(FrmBid, 'getTransactions', {}), // [action, 'getTransactions', {}],
    async () => {
      return axios
        .post<RouterAPIData<GetTransactionsData[]>>('/api/router.php', {
          action: FrmBid,
          method: 'getTransactions',
          data: [{}],
          type: 'rpc',
          tid: 6,
        })
        .then(checkSessionTimeout)
        .then((res) => res.data.result.data);
    }
  );
}

export async function getLastJournalEntry(data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmLaJournalEntry',
      method: 'getLaLastEntries',
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data);
}

export async function resolveXMLInvoice(data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmLaJournalEntry',
      method: 'resolveXMLInvoice',
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data);
}

export async function uploadFile(body: any) {
  return fetch('/api/router.php', {
    method: 'post',
    body,
  })
    .then((resp) => resp.json())
    .then((data) => data)
    .catch((err) => err);
}

export async function previewFile(variables = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmTransfer',
      method: 'preview',
      data: [variables],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function getDataSheet(data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmReportViewer',
      method: 'get',
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function deleteDataSheet(data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmReportViewer',
      method: 'delete',
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function syncDataSheet(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmReportViewer',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function syncDataAccessGroup(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmDataAccessGroup',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

// export async function apiReportDefinition(method: string, data = {}) {
//   return axios
//     .post('/api/router.php', {
//       action: 'FrmReportDefinition',
//       method,
//       data: [data],
//     })
//     .then(checkSessionTimeout)
//     .then((res) => res.data.result.data || res.data.result);
// }

export async function formReportDefinition(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmReportDefinition',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function formReportChart(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmReportChart',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function syncDbTemplate(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmDbTemplate',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

const action = 'FrmDbTemplate';

export function useListDbTemplatesQuery() {
  return useQuery<DbTemplateData[]>([action, 'list'], async () => {
    const res = await axios.post<RouterAPIData<DbTemplateData[]>>(
      '/api/router.php',
      {
        action,
        method: 'list',
        data: [{}],
      }
    );
    return res.data.result.data;
  });
}

export async function syncLaJournalEntry(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmLaJournalEntry',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

export async function syncUserCredential(method: string, data = {}) {
  return axios
    .post('/api/router.php', {
      action: 'FrmUserCredential',
      method,
      data: [data],
    })
    .then(checkSessionTimeout)
    .then((res) => res.data.result.data || res.data.result);
}

// Plaid Integration
export async function GetLinkToken(client_user_id: string) {
  return axios
    .post('/plaid/api/create_link_token', {
      client_user_id
    })
    .then(checkSessionTimeout)
    .then((res) => res);
}

export async function exchangePublicTokenForAccessToken(client_user_id: string, public_token: string) {
  return axios
    .post('/plaid/api/set_access_token', {
      client_user_id,
      public_token,
    })
    .then(checkSessionTimeout)
    .then((res) => res);
}
