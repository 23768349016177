import { LoadableComponent } from '@loadable/component';
import { useEffect, useRef } from 'react';
import { proxy } from 'valtio';
import { proxyMap } from 'valtio/utils';

import { WindowContextValueItem, ExtObjectParams, SizePosType } from './types';

export const marketplace = '101';
export const sharestepper = 'share-knowledge-stepper';
export const reportstepper = 'connect-stepper';
export const authorizestepper = 'authorize-stepper';
export const accountstepper = 'account-stepper';
export const journalstepper = 'journal-stepper';
export const importstepper = 'import-stepper';
export const calculator = 'calculator';
export const journalreader = 'journal-reader';
export const datasheetselector = 'data-sheet-selector';
export const expressioneditor = 'expressioneditor';
export const holdbookpanel = '79'; // The ID given in the tblmenus table
export const integrationpanel = '99';
export const paymentrequestpanel = 'paymentrequestpanel';

export const state = proxy({
  windows: proxyMap<string, WindowContextValueItem>(),
  focused: '',
});

export const hasWindow = (id: string) => {
  return state.windows.has(id);
};

export const getWindow = (id: string) => {
  return state.windows.get(id);
};

const setActive = (id: string, active: boolean) => {
  // If a window is inactive (active = false), blur and
  // make it not visible (by setting opacity). Otherwise
  // remove blur, if any
  const w = getWindow(id);

  if (!w) {
    console.warn(`window #${id} NOT found. Cannot activate/inactivate`);
    return false;
  }

  if (!active) {
    w.zIndex = 0;
  }

  state.windows.set(id, { ...w, active });
  return true;
};

export const closeWindow = (id: string, action = 'closed') => {
  const w = getWindow(id);

  if (!w) {
    console.warn(`window #${id} NOT found. Cannot close`);
    return false;
  }

  if (state.focused === id) {
    hideWindow(id);
  }

  // Fire destroy event for Ext component, if it is
  if (typeof w.onDestroy === 'function') {
    w.onDestroy();
  }

  state.windows.delete(id);

  if (w.onAction && typeof w.onAction === 'function') {
    w.onAction(action, id);
  }
  return true;
};

export const createWindow = (
  id: string,
  opts: {
    title?: string;
    Component: React.FC<any> | LoadableComponent<any>;
    onUpdate?: WindowContextValueItem['onUpdate'];
    onAction?: WindowContextValueItem['onAction'];
    props?: any;
  },
  maximized?: boolean,
  initial?: SizePosType
) => {
  if (hasWindow(id)) {
    console.warn(`[AppWindowStore] cannot use duplicate id: ${id}`);
    return;
  }

  if (!opts.title) {
    console.log(`[AppWindowStore] ${id} does not have a title`);
    opts.title = '';
  }

  const newWin: WindowContextValueItem = {
    id,
    element: <opts.Component {...opts.props} />,
    title: opts.title,
    active: true,
    maximized: !!maximized,
    initial,
    zIndex: 1,
    onUpdate: opts.onUpdate,
    onAction: opts.onAction,
  };

  state.windows.set(id, newWin);

  setFocus(id);

  return newWin;
};

export const closeAll = () => {
  for (const [key, w] of state.windows.entries()) {
    closeWindow(key);
  }
};

export const setFocus = (id: string) => {
  // A window is active (showing on the screen)
  // its content is on top most of the other windows
  if (state.focused === id) {
    return true;
  }

  const w = getWindow(id);
  if (!w) {
    console.warn(`window #${id} NOT found. Cannot be focused`);
    return false;
  }

  for (const [key, v] of state.windows.entries()) {
    if (v.id !== id && w.zIndex <= v.zIndex) {
      w.zIndex = v.zIndex + 1;
    }
  }

  if (w.zIndex === 0) {
    // If this is only inactive window, then bring it forward
    w.zIndex = 1;
  }

  state.windows.set(id, { ...w, active: true });
  state.focused = id; // console.log('focused: %s', id);

  return true;
};

export const hideWindow = (id: string) => {
  // Hide this window behind active windows
  const win = getWindow(id);
  if (!win) {
    console.warn(`window #${id} NOT found. Cannot be hidden`);
    return false;
  }

  // If the current focus is on this window, then
  // set focus to the foremost window
  if (state.focused === id) {
    const foremost = {
      id,
      zIndex: 0,
    };

    for (const [key, w] of state.windows.entries()) {
      // Find the foremost active window
      if (w.id !== id && foremost.zIndex < w.zIndex) {
        foremost.id = w.id;
        foremost.zIndex = w.zIndex;
      }
    }

    // Show the foremost window
    if (foremost.zIndex > 0) {
      setFocus(foremost.id);
    } else {
      state.focused = '';
    }
  }

  // Blur window by setting opacity
  setActive(id, false);

  return true;
};

export const showWindow = (id: string) => {
  return setFocus(id);
};

let extIndex = 0;
export const createExtWindow = (
  id = String(++extIndex),
  extParams: Partial<ExtObjectParams>,
  fn?: (any: any) => void,
  maximized = true,
  initial?: SizePosType
) => {
  if (hasWindow(id)) {
    return;
  }

  let ext: any;
  const ExtComponent: React.FC = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      console.log('mounted');
      if (ext) {
        console.log('I exist');
        return;
      }
      if (extParams._xclass) {
        extParams.xclass = extParams._xclass;
      }
      if (extParams._xtype) {
        extParams.xtype = extParams._xtype;
        extParams.id = (extParams.xtype || '') + id;
      }

      // if (extParams.text) {
      //   extParams.title = extParams.text;
      // }

      if (!extParams.xclass) {
        console.warn(
          '[createExtComponent] WARN: No xclass specified, fallback to using Ext.Component'
        );
        return;
      }
      ext = window.Ext.create({
        ...extParams,
        renderTo: ref.current,
        close: (action: string) => closeWindow(id, action),
      });

      if (typeof fn === 'function') {
        fn(ext);
      }

      const w = state.windows.get(id);
      if (w) {
        w.onDestroy = ext.destroy.bind(ext);
      }

      ext.show();
    }, []);

    return <div ref={ref} />;
  };

  createWindow(
    id,
    {
      Component: ExtComponent,
      title: extParams.title || extParams.text,
      onAction: extParams.onAction,
      onUpdate({ width, height }) {
        if (width && height) {
          ext?.setSize(width, height);
        }
      },
    },
    maximized,
    initial
  );

  return ext;
};

window.createExtWindow = createExtWindow;

export default state;
