import { useQuery } from 'react-query';
import axios from 'redaxios';
import { RouterAPIData } from 'features/common/types';
import { GetCountriesData, GetCurrencyData } from './types';
import { throwIfGatewayTimeout } from 'api/proxy';

const action = 'FrmUtils';

// Since FrmUtils data does not (often) change
// there is no need for auth query
const queryConfig = {
  refetchOnMount: false,
  staleTime: Infinity,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export function useGetCountriesQuery() {
  return useQuery<GetCountriesData[]>(
    [action, 'getCountries'],
    () =>
      axios
        .post<RouterAPIData<GetCountriesData[]>>('/api/router.php', {
          action,
          method: 'getCountries',
          data: [{}],
        })
        .then((res) => res.data.result.data)
        .catch(throwIfGatewayTimeout),
    queryConfig
  );
}

export function useGetCurrencyQuery() {
  return useQuery<GetCurrencyData[]>(
    [action, 'getCurrency'],
    () =>
      axios
        .post<RouterAPIData<GetCurrencyData[]>>('/api/router.php', {
          action,
          method: 'getCurrency',
          data: [{}],
        })
        .then((res) => res.data.result.data)
        .catch(throwIfGatewayTimeout),
    queryConfig
  );
}
