import { makeStyles } from 'tss-react/mui';
import AppSidebar from './AppSidebar';
import AppTaskbar from './AppTaskbar';
import AppTopbar from './AppTopbar';

const useStyles = makeStyles()(() => ({
  root: {
    backgroundColor: '#f4f6f8',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
}));

const AppLayout = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <AppSidebar />
      <AppTopbar />
      {children}
      <AppTaskbar />
    </div>
  );
};

export default AppLayout;
