import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Command from 'constant/command';
import { error, info, warn, executePromise } from '@knowins/notifier';
import PlaidLink from 'components/PlaidLink';
import theme from '../../theme';
import { pSBC } from '../../helper/color';

const BUTTONS = {
  OPEN: 'OPEN',
  REPORT: 'REPORT',
  IMPORT: 'IMPORT',
  INVOICING: 'INVOICING',
  PLAID: 'PLAID',
};

export const createButton = (
  moneta: string,
  nodeEl: any,
  title: string,
  onClick: (data: any, vm: any, tbar: any) => any,
  refresh,
  vm?: any,
  tbar?: any
) => {
  const s = window.getComputedStyle(nodeEl);
  const { backgroundColor, color, font } = s;
  // nodeEl.className = '';

  const handleClick = async (btnID: string, innerText: string) => {
    let match;
    switch (btnID) {
      // case BUTTONS.IMPORT:
      //   re = /^IMPORT\s*\([\s\w,;&()]+\)$/i;
      //   if (re.test(title)) {
      //     const titles = title.split('&');
      //     const params = titles.map((t) => {
      //       const cmd = t.trim();

      //       re = /^IMPORT\s*\(([^)]*)\)$/i;
      //       const match = re.exec(cmd);
      //       if (!match) {
      //         error('status.wrong_import_formula');
      //         return null;
      //       } else {
      //         // Get QUERY_DB group
      //         const params = match[1];
      //         let arr = params.split(',');
      //         if (arr.length < 3) {
      //           arr = params.split(';');
      //           if (arr.length < 3) {
      //             error('status.invalid_formula');
      //             return null;
      //           }
      //         }

      //         const [_1, _2, ...headers] = arr;
      //         const fn = _1.trim();
      //         const dataTag = _2.trim();

      //         if (!fn || !headers || !dataTag) {
      //           error('status.invalid_formula');
      //           return null;
      //         } else if (view && view.el && view.el.dom) {
      //           const dom = view.el.dom;
      //           const raw = dom.querySelector(`[title="${dataTag}"]`);
      //           if (!raw) {
      //             error('status.import_data_tag_not_found');
      //             return null;
      //           } else {
      //             // const header = others.replace(/\s/g, '').substring(1);
      //             // const headers = header.split(',');
      //             const len = headers.length;
      //             if (len === 0) {
      //               error('status.import_data_columns_not_found');
      //               return null;
      //             } else {
      //               const fields = headers.map((d) => d.trim());
      //               if (raw.innerText === '#N/A') {
      //                 error('status.import_data_NA');
      //                 return null;
      //               } else if (raw.innerText === '#VALUE!') {
      //                 error('status.import_data_VALUE');
      //                 return null;
      //               }

      //               const raws = raw.innerText.split('|');

      //               let count = -1;
      //               let data = [];
      //               let skipRow = false;
      //               let emptyRow = false;
      //               for (let j = 0; j < raws.length; j++) {
      //                 if (j % len === 0) {
      //                   // Next line
      //                   if (skipRow || emptyRow) {
      //                     skipRow = false;
      //                   } else {
      //                     count++;
      //                   }
      //                   data[count] = {};
      //                   emptyRow = true;
      //                 }

      //                 // Check if this column is skipped
      //                 const field = fields[j % len];
      //                 const value = raws[j].trim();
      //                 if (
      //                   field &&
      //                   !['skip', '__skip__', 'ignore', '__ignore__'].includes(
      //                     field.toLowerCase()
      //                   )
      //                 ) {
      //                   data[count][field] = value;
      //                   if (value != '') {
      //                     emptyRow = false;
      //                   }
      //                 }

      //                 // Check if this row is skipped
      //                 if (
      //                   value &&
      //                   ['_skip_', '__skip__'].includes(value.toLowerCase())
      //                 ) {
      //                   skipRow = true;
      //                 }
      //               }

      //               // Case where the last row is skipped
      //               if (skipRow || emptyRow) {
      //                 data.splice(-1, 1); // Remove the last row
      //               }

      //               // Make sure the mandantory fields
      //               if (
      //                 [
      //                   'JNI',
      //                   'JNE',
      //                   'JENTRY',
      //                   'JIQ',
      //                   'JINQUIRY',
      //                   'JNQ',
      //                   'JQUERY',
      //                 ].includes(fn)
      //               ) {
      //                 if (!fields.includes('journalno')) {
      //                   // Case: newly insert journal
      //                   data = data.map((d) => ({
      //                     ...d,
      //                     journalno: -1,
      //                   }));

      //                   if (!fields.includes('journalline')) {
      //                     // Case: newly insert journal
      //                     data = data.map((d, idx) => ({
      //                       ...d,
      //                       journalline: idx + 1,
      //                     }));
      //                   }
      //                 }
      //               }

      //               return { action: Command.IMPORT, fn, data };
      //             }
      //           }
      //         }
      //       }
      //     });

      //     if (params.length === 1) {
      //       return onClick(params[0], vm, tbar);
      //     } else if (params.length > 1) {
      //       try {
      //         // @ts-ignore
      //         return params.reduce(async (acc, curr) => {
      //           if (acc.length > 0) {
      //             info('status.running_next_task');
      //           }
      //           return [...(await acc), await onClick(curr, vm, tbar)];
      //         }, []);
      //       } catch (err) {
      //         // Will catch failure of first failed promise
      //         console.log('Failed:', err);
      //         error('status.import_skip_next_action');
      //       }
      //     }
      //   }
      //   break;
      case BUTTONS.REPORT:
        re = /^\s*REPORT\s*\((.|\s)*/i;
        if (re.test(title)) {
          re = /^(\s|\t)*REPORT[\s\t]*\(((.|\n|\r)*)(\)|\)[\s,\t]*)$/i;
          match = re.exec(title);
          if (!match || !match[2]) {
            error('status.wrong_report_formula');
          } else {
            // Get QUERY_DB group
            const query = match[2];
            // const { moneta } = readLocalStorage();
            return onClick(
              {
                action: Command.REPORT,
                query,
                data: { name: 'Report', owner: moneta },
              },
              vm,
              tbar
            );
          }
        }
        break;
      case BUTTONS.OPEN:
        re = /^OPEN\s*\(\s*([\w\s\:\.,_-]+)\s*\)$/i; //eslint-disable-line
        match = re.exec(title);
        if (!match || !match[1]) {
          warn('status.invalid_open_formular');
        } else {
          const params = match[1];
          let arr = params.split(',');
          if (arr.length < 2) {
            arr = params.split(';');
          }

          const [_1, _2, ...headers] = arr;
          const shortCmd = (_1 || '').trim();
          const primaryKey = (_2 || '').trim();
          const fields = headers
            ? headers.reduce((acc, v) => {
                const parts = v.split(':');
                const fieldName = (parts[0] || '').trim();
                if (fieldName) {
                  acc[fieldName] = (parts[1] || '').trim();
                }
                return acc;
              }, {})
            : {};

          return onClick(
            {
              action: Command.OPEN,
              fn: shortCmd,
              data: { _key: primaryKey, _text: innerText, ...fields },
            },
            vm,
            tbar
          );
        }
        break;
      default:
    }
  };

  let buttonId;

  let re = /^BUTTON\s*:\s?([\w._-]*)/i;
  if (re.test(title)) {
    const match = re.exec(title);
    if (!match || !match[1]) {
      warn('status.invalid_button_formular');
    } else {
      buttonId = match[1].trim();
    }
  }

  re = /^REPORT\s*\((.|\s)*/i;
  if (re.test(title)) {
    buttonId = BUTTONS.REPORT;
  }

  re = /^IMPORT\s*\((.|\s)*/i;
  if (re.test(title)) {
    buttonId = BUTTONS.IMPORT;
  }

  re = /^OPEN.*/i;
  if (re.test(title)) {
    buttonId = BUTTONS.OPEN;
  }

  re = /^INVOICING\s*\((.|\s)*/i;
  if (re.test(title)) {
    buttonId = BUTTONS.INVOICING;
  }

  if (buttonId) {
    nodeEl.removeAttribute('title');
    nodeEl.setAttribute('data-title', title);
    if (buttonId === BUTTONS.INVOICING) {
      nodeEl.parentNode.setAttribute('data-params', title);
    }
    // else if (buttonId === BUTTONS.OPEN) {
    //   nodeEl.setAttribute('data-text', nodeEl.innerText);
    // }
    console.log('nodeEl.innerText:', nodeEl.innerText);
    const root = createRoot(nodeEl);
    root.render(
      <ThemeProvider theme={theme}>
        {buttonId.toUpperCase() === BUTTONS.PLAID ? (
          <PlaidLink title={nodeEl.innerText} refresh={refresh} textClassName={nodeEl.className}/>
        ) : (
          <Button
            variant="contained"
            size="small"
            // color="primary"
            sx={[
              {
                '&:hover': {
                  backgroundColor: pSBC(-0.38, backgroundColor),
                  color: pSBC(0.38, color),
                },
              },
              {
                textTransform: 'none',
                width: nodeEl.clientWidth - 3,
                height: nodeEl.clientHeight - 2,
                backgroundColor,
                color,
                font,
              },
            ]}
            data-params={title}
            onClick={() => handleClick(buttonId, nodeEl.innerText)}
          >
            {nodeEl.innerText}
          </Button>
        )}
      </ThemeProvider>
    );
  }
};
