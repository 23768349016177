import { proxy } from 'valtio';

const state = proxy<{
  chainId: string;
  open: boolean;
  session: any;
  args: any;
  authorization: Array<any>;
  selected: string;
  version: number;
  uri: string;
}>({
  chainId: 'eip155:61', // 'eip155:5',
  open: false,
  session: null,
  args: null,
  authorization: [],
  selected: '',
  version: 0,
  uri: '',
});

export default state;
