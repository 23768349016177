export const CRUD = {
  run: 'run',
  new: 'new',
  get: 'get',
  create: 'create',
  read: 'read',
  update: 'update',
  delete: 'delete',
  select: 'select',
  cancel: 'cancel',
  download: 'download',
  getLaHoldEntries: 'getLaHoldEntries',
};

const CONFIG = Object.freeze({
  stores: {
    status: [
      ['0', 'Suspend'],
      ['1', 'Active'],
    ],
    inquiryby: [
      ['', 'None'],
      ['period', 'Period'],
      ['d_c', 'Credit | Debit'],
      ['journalno', 'Journal No'],
      ['accountcode', 'Account No'],
      ['addresscode', 'Address Code'],
      ['allocationref', 'Allocation Ref'],
      ['entrydate', 'Entry Date'],
      ['transactiondate', 'Trans. Date'],
    ],
    ledger: [
      ['A', 'Main Ledger'],
      ['B', 'Budget Ledger'],
      ['C', 'Purchase Ledger'],
      ['E', 'Revenue Ledger'],
    ],
    baseline: [['B', 'Base Line Value']],
    period: [
      ['C', 'Current Accounting Period'],
      ['B', 'Base Line Value'],
    ],
    presetdate: [
      ['T', 'Today'],
      ['B', 'Base Line Value'],
    ],
    accounttype: [
      ['', 'None'],
      ['D', 'Debtor'],
      ['C', 'Creditor'],
      ['T', 'Client'],
      ['P', 'Profit & Loss'],
      ['B', 'Balance Sheet'],
      ['M', 'Memo'],
    ],
    addresstype: [
      ['', 'None'],
      ['S', 'Seller'],
      ['B', 'Buyer'],
      ['BNK', 'Banker'],
    ],
    assetindicator: [
      ['', 'None'],
      ['D', 'Depreciation'],
      ['I', 'Initial'],
    ],
    entryform: [
      ['frmlajournalentry', 'Journal Entry', ['additional', 'mapping']],
    ],
    entryfield: [
      ['taxgroup', 'Tax Group', 'frmlajournalentry'],
      ['reference0', 'Reference 0', 'frmlajournalentry'],
      ['reference1', 'Reference 1', 'frmlajournalentry'],
      ['reference2', 'Reference 2', 'frmlajournalentry'],
      ['reference3', 'Reference 3', 'frmlajournalentry'],
      ['reference4', 'Reference 4', 'frmlajournalentry'],
      ['date0', 'Date 0', 'frmlajournalentry'],
      ['date1', 'Date 1', 'frmlajournalentry'],
      ['date2', 'Date 2', 'frmlajournalentry'],
      ['date3', 'Date 3', 'frmlajournalentry'],
      ['date4', 'Date 4', 'frmlajournalentry'],
      ['number0', 'Number 0', 'frmlajournalentry'],
      ['number1', 'Number 1', 'frmlajournalentry'],
      ['number2', 'Number 2', 'frmlajournalentry'],
      ['number3', 'Number 3', 'frmlajournalentry'],
      ['number4', 'Number 4', 'frmlajournalentry'],
      ['description0', 'Description 0', 'frmlajournalentry'],
      ['description1', 'Description 1', 'frmlajournalentry'],
      ['description2', 'Description 2', 'frmlajournalentry'],
      ['description3', 'Description 3', 'frmlajournalentry'],
      ['description4', 'Description 4', 'frmlajournalentry'],
    ],
    mappingfield: [
      ['taxgroup', 'Tax Group', 'frmlajournalentry'],
      ['reference0', 'Reference 0', 'frmlajournalentry'],
      ['reference1', 'Reference 1', 'frmlajournalentry'],
      ['reference2', 'Reference 2', 'frmlajournalentry'],
      ['reference3', 'Reference 3', 'frmlajournalentry'],
      ['reference4', 'Reference 4', 'frmlajournalentry'],
    ],
    integration: [['EASYINVOICE', 'Easy Invoice']],
  },

  PROJECT_ID: '1854555a752db30bba2d5b217e3bbd5d',

  TbarView: {
    List: [
      'Run',
      'View',
      'Add',
      'Copy',
      'Edit',
      'Delete',
      'Refresh',
      'Import',
      'Export',
      'Document',
      'Lang',
      'Training',
    ],
    View: [
      'Next',
      'Back',
      'First',
      'Run',
      'Last',
      'Find',
      'Add',
      'Copy',
      'Edit',
      'Delete',
      'Download',
      'DownTemplate',
      'Excel',
      'Chart',
      'Table',
      'Document',
      'Visualize',
      'Print',
      'Lang',
    ],
    Add: [
      'Cancel',
      'Save',
      'Document',
      'Download',
      'DownTemplate',
      'UpTemplate',
      'Lang',
    ],
    Edit: [
      'Cancel',
      'Save',
      'Document',
      'Download',
      'DownTemplate',
      'UpTemplate',
      'Lang',
    ],
    Copy: [
      'Cancel',
      'Save',
      'Document',
      'Download',
      'DownTemplate',
      'UpTemplate',
      'Lang',
    ],
    Run: ['Find', 'Excel', 'Table', 'Document', 'Print', 'Lang'],
    CancelEdit: [
      'Next',
      'Back',
      'First',
      'Last',
      'Find',
      'Run',
      'Add',
      'Copy',
      'Edit',
      'Delete',
      'Download',
      'DownTemplate',
      'Excel',
      'Chart',
      'Table',
      'Document',
      'Visualize',
      'Print',
      'Lang',
    ],
    CancelCreate: [
      'Next',
      'Back',
      'First',
      'Last',
      'Find',
      'Visualize',
      'Lang',
    ],
    Hold: ['Run', 'Edit', 'Delete', 'Refresh'],
    Start: ['Run', 'Clone', 'Refresh', 'Lang'], // 'Import', 'Export', 'Document',
    Entry: [
      'Find',
      'Add',
      'Copy',
      'Edit',
      'Cancel',
      'Save',
      'Delete',
      'Import',
      'Export',
      'Document',
      'EntryView',
      'Hold',
      'Book',
      'Finalize',
      'Print',
      'Lang',
    ],
    Recall: [
      'Copy',
      'Edit',
      'Cancel',
      'Save',
      'Delete',
      'Export',
      'Document',
      'EntryView',
      'Book',
      'Finalize',
      'Print',
      'Lang',
    ],
    Inquiry: [
      'Edit',
      'Split',
      'Recall',
      'Allocation',
      'Payment',
      'MatchAmt',
      'MatchOAmt',
      'Unmatch',
      'Report',
      'Refresh',
      'Save',
    ],
    InquiryEdit: ['Cancel', 'Book', 'Finalize', 'Lang'],
    OkCancel: ['Cancel', 'Save', 'Lang'],
  },

  ExcelFileType: [
    'application/vnd.ms-excel',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],

  FORM_FIELD_DEFAULTS: {
    padding: 1,
    //columnWidth: 1,
    width: 390,
    labelWidth: 150,
    labelSeparator: ' ',
    labelAlign: 'right',
  },

  SHORT_COMMANDS: {
    //?: 'Training',
    C: 'Add',
    A: 'Edit',
    D: 'Delete',
    S: 'Save',
    V: 'View',
    L: 'Find',
    R: 'Print',
    H: 'Hold',
    P: 'Post', // 'Book'
    PR: 'PostRough',
    SA: 'Start',
    OA: 'Allocate',
    CP: 'Copy',
    CL: 'Cancel',
    N: 'Next',
    B: 'Back',
    FF: 'First',
    LL: 'Last',
    TI: 'Import',
    TO: 'Export',
    XL: 'Excel',
    RC: 'Recall',
    AND: 'FrmCsAnalysisDefinition',
    ANC: 'FrmCsAnalysisCode',
    CUR: 'FrmCsCurrencyCode',
    COT: 'FrmCsContact',
    NAD: 'FrmCsAddress',
    CUS: 'FrmCsCustomer',
    SUP: 'FrmCsSupplier',
    LKC: 'FrmCsLookupCode',
    ADF: 'FrmCsAdditionalField',
    COA: 'FrmLaChartAccount',
    JND: 'FrmLaJournalDefinition',
    JNP: 'FrmLaJournalPreset',
    BNK: 'FrmLaBank',
  },
  CMD: {
    CUS: {
      xtype: 'cscustomerpanel',
      xclass: 'MoneFox.view.cs.customer.CsCustomerPanel',
      title: 'Customer',
    },
    SUP: {
      xtype: 'cssupplierpanel',
      xclass: 'MoneFox.view.cs.supplier.CsSupplierPanel',
      title: 'Supplier',
    },
    COT: {
      xtype: 'cscontactpanel',
      xclass: 'MoneFox.view.cs.contact.CsContactPanel',
      title: 'Contact',
    },
    NAD: {
      xtype: 'csaddresspanel',
      xclass: 'MoneFox.view.cs.address.CsAddressPanel',
      title: 'Name & Address',
    },
    SQN: {
      xtype: 'cssequencedefinitionpanel',
      xclass: 'MoneFox.view.cs.reference.SequenceDefinitionPanel',
      title: 'Sequence Number',
    },
    ADF: {
      xtype: 'csadditionalfieldpanel',
      xclass: 'MoneFox.view.cs.extend.CsAdditionalFieldPanel',
      title: 'Extended Field',
    },
    LKC: {
      xtype: 'cslookupcodepanel',
      xclass: 'MoneFox.view.cs.analysis.CsLookupCodePanel',
      title: 'Lookup Code',
    },
    MPS: {
      xtype: 'csmappingsetuppanel',
      xclass: 'MoneFox.view.cs.mapping.CsMappingSetupPanel',
      title: 'Field Mapping',
    },
    CUR: {
      xtype: 'cscurrencycodepanel',
      xclass: 'MoneFox.view.cs.currency.CsCurrencyCodePanel',
      title: 'Currency Code',
    },
    CNP: {
      xtype: 'cscurrencyperiodratepanel',
      xclass: 'MoneFox.view.cs.currency.CsCurrencyPeriodRatePanel',
      title: 'Period Rate',
    },
    CND: {
      xtype: 'cscurrencydailyratepanel',
      xclass: 'MoneFox.view.cs.currency.CsCurrencyDailyRatePanel',
      title: 'Daily Rate',
    },
    AND: {
      xtype: 'csanalysisdefinitionpanel',
      xclass: 'MoneFox.view.cs.analysis.CsAnalysisDefinitionPanel',
      title: 'Definition',
    },
    ANC: {
      xtype: 'csanalysiscodepanel',
      xclass: 'MoneFox.view.cs.analysis.CsAnalysisCodePanel',
      title: 'Analysis Code',
    },
    RPD: {
      xtype: 'csreportdefinitionpanel',
      xclass: 'MoneFox.view.rp.reportdefinition.RpReportDefinitionPanel',
      title: 'Data View',
    },
    RGD: {
      xtype: 'reportgrouppanel',
      xclass: 'MoneFox.view.rp.reportgroup.ReportGroupPanel',
      title: 'Organizing',
    },
    DDL: {
      xtype: 'dynamicdatalinkpanel',
      xclass: 'MoneFox.view.rp.reportdata.DynamicDataLinkPanel',
      title: 'Data Set',
    },
    DBC: {
      xtype: 'csdbconnectorpanel',
      xclass: 'MoneFox.view.cs.dbconnector.CsDbConnectorPanel',
      title: 'Data Source',
    },
    JNI: {
      xtype: 'lajournalimportpanel',
      xclass: 'MoneFox.view.la.journal.LaJournalImportPanel',
      title: 'Journal Import',
    },
    HBK: {
      xtype: 'react',
      xclass: 'module/la/HoldBook',
      title: 'Hold Book',
    },
    ITG: {
      xtype: 'react',
      xclass: 'module/la/Integration',
      title: 'Integration',
    },
    CRT: {
      xtype: 'lacreditorinquirypanel',
      xclass: 'MoneFox.view.la.inquiry.LaCreditorInquiryPanel',
      title: 'Creditor',
    },
    DBT: {
      xtype: 'ladebtorinquirypanel',
      xclass: 'MoneFox.view.la.inquiry.LaDebtorInquiryPanel',
      title: 'Debtor',
    },
    JNQ: {
      xtype: 'lajournalinquirypanel',
      xclass: 'MoneFox.view.la.inquiry.LaJournalInquiryPanel',
      title: 'Journal',
    },
    JND: {
      xtype: 'lajournaldefinitionpanel',
      xclass: 'MoneFox.view.la.journaldefinition.LaJournalDefinitionPanel',
      title: 'Journal Type',
    },
    JNP: {
      xtype: 'lajournalpresetpanel',
      xclass: 'MoneFox.view.la.journalpreset.LaJournalPresetPanel',
      title: 'Journal Preset',
    },
    LED: {
      xtype: 'laledgerdefinitionpanel',
      xclass: 'MoneFox.view.la.ledgerdefinition.LaLedgerDefinitionPanel',
      title: 'Ledger Definition',
    },
    CBG: {
      xtype: 'lachangebudgetpanel',
      xclass: 'MoneFox.view.la.budget.LaChangeBudgetPanel',
      title: 'Change Budget',
    },
    COA: {
      xtype: 'lachartaccountpanel',
      xclass: 'MoneFox.view.la.chartaccount.LaChartAccountPanel',
      title: 'Chart of Account',
    },
    BNK: {
      xtype: 'labankpanel',
      xclass: 'MoneFox.view.la.bank.LaBankPanel',
      title: 'Bank Detail',
    },
    NWP: {
      xtype: 'wdperiodinput',
      xclass: 'MoneFox.view.la.ledgerdefinition.WdPeriodInput',
      title: 'New Period',
    },
  },
});

export default CONFIG;
