import { useState, useEffect } from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { TextField, Container, Button, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import axios from 'redaxios';
import { makeStyles } from 'tss-react/mui';

import { LoadingFullpage } from 'features/Loading';
import { error, executePromise } from '@knowins/notifier';

import bcrypt from 'bcryptjs-react';

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [loading, setLoading] = useState(true);
  const [expired, setExpired] = useState(false);
  const { token = '' } = useParams();

  const navigate = useNavigate();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      password: '',
      confirm_new_password: '',
    },
  });

  useEffect(() => {
    (async function checkToken() {
      const { data } = await axios.get(
        `/authenticate/api/password/verify/${token}`
      );

      const { success, token: serverToken } = data;
      if (!success || token !== serverToken) {
        setLoading(false);
        setExpired(true);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (data: {
    password: string;
    confirm_new_password: string;
  }) => {
    const { password, confirm_new_password } = data;
    if (password !== confirm_new_password) {
      return error(t('login.password_mismatched')); // , t('common.dismiss')
    }

    const salt = bcrypt.genSaltSync(10);
    const encryptedpassword = bcrypt.hashSync(password, salt);

    executePromise(
      axios
        .post(`/authenticate/api/password/change/${token}`, {
          encryptedpassword,
        })
        .then(({ data }) => {
          if (data?.success) {
            navigate('/login');
            return { success: true };
          }
          return { success: false };
        }),
      {
        loading: 'status.sending_request',
        error: 'status.password_failed',
        success: 'status.password_changed',
      }
    );
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <img
        src="/assets/knowins-slogan.svg"
        className={classes.slogan}
        alt="who knows win"
      />
      {loading ? (
        <LoadingFullpage />
      ) : expired ? (
        <div>
          <Typography style={{ marginBottom: 33 }} align="center" variant="h6">
            {t('login.reset_password_expired')}
            {'.'}
          </Typography>
          <Typography
            style={{ marginBottom: 0 }}
            align="center"
            color="textSecondary"
            variant="subtitle1"
            component="p"
            paragraph
          >
            {t('login.please_click')}{' '}
            <Link component={RouterLink} to="/forgot-password">
              {t('login.here')}
            </Link>{' '}
            {t('login.to_send_the_email_again')}
            {'.'}
          </Typography>
        </div>
      ) : (
        <div>
          <Typography style={{ marginBottom: 33 }} align="center" variant="h6">
            {t('login.reset_password')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  required
                  fullWidth
                  label={t('user.new_password')}
                  type="password"
                  variant="standard"
                />
              )}
            />
            <Controller
              name="confirm_new_password"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="dense"
                  required
                  fullWidth
                  label={t('user.confirm_new_password')}
                  type="password"
                  variant="standard"
                />
              )}
            />
            <Button
              sx={{ m: '1rem 0 0 0' }}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
            >
              {t('user.save')}
            </Button>
          </form>
        </div>
      )}
    </Container>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(12, 4),
    textAlign: 'center',
  },
  slogan: {
    width: theme.spacing(88),
  },
}));

export default ResetPassword;
