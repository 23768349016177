import React, { useEffect, useState, useCallback, useContext } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { makeStyles } from 'tss-react/mui';
import Button from 'plaid-threads/Button';
import BankIcon from '@mui/icons-material/AccountBalance';

import { error, info, warn, executePromise } from '@knowins/notifier';
import {
  GetLinkToken,
  exchangePublicTokenForAccessToken,
} from 'api/repository';
// import { readLocalStorage } from 'helper';
// import Button from '@mui/material/Button';

// import Context from "../../Context";

type Props = {
  title: string;
  textClassName?: string;
  refresh: (options?: object) => void;
};

const useStyles = makeStyles()((theme) => ({
  button: {
    height: 'inherit !important',
  },
}));

const PlaidLink = (props: Props) => {
  const { refresh, title, textClassName } = props;
  const [linkToken, setLinkToken] = useState('');
  const { classes, cx } = useStyles();

  const client_user_id = localStorage.getItem('moneta') || '';
  const login = localStorage.getItem('login');

  const onSuccess = React.useCallback((public_token: string) => {
    // If the access_token is needed, send public_token to server
    executePromise(
      exchangePublicTokenForAccessToken(client_user_id, public_token),
      {
        loading: 'status.authorizing',
        success: (res) => {
          console.log('res.........', res);
          const { success, access_token, item_id } = res;
          console.log('data.......', { access_token, item_id });
          if (success === true) {
            refresh([
              {
                name: 'Parameter',
                node: 'raw_param_filter',
                operator: 'Parameter',
                // isnot: 'N',
                // isfilter: true,
                // reportdetailid: 0,
                // isnot_checked: false,
                // reportcode: 'TEST_PLAID',
                filterfrom: 'plaid_access_token',
                filterto: item_id,
              },
            ]);
            return res;
          }
        },
      }
    );

    // // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
    // if (false) {
    //   // dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
    // } else {
    //   exchangePublicTokenForAccessToken();
    // }

    // dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
    // window.history.pushState('', '', '/');
  }, []);

  let isOauth = false;
  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess,
  };

  console.log('config', config);

  if (window.location.href.includes('?oauth_state_id=')) {
    // TODO: figure out how to delete this ts-ignore
    // @ts-ignore
    config.receivedRedirectUri = window.location.href;
    isOauth = true;
  }

  const { open, ready } = usePlaidLink(config);

  // useEffect(() => {
  //   const init = async () => {
  //     // const { paymentInitiation } = await getInfo(); // used to determine which path to take when generating token
  //     // do not generate a new token for OAuth redirect; instead
  //     // setLinkToken from localStorage
  //     if (window.location.href.includes("?oauth_state_id=")) {
  //       // dispatch({
  //       //   type: "SET_STATE",
  //       //   state: {
  //       //     linkToken: localStorage.getItem("link_token"),
  //       //   },
  //       // });
  //       return;
  //     }

  //   };
  //   init();
  // }, [generateToken]);

  useEffect(() => {
    if (ready) {
      console.log('open plaid dialog');
      open();
    }
  }, [ready, open]);

  const handleClick = async () => {
    if (!client_user_id || !login || login !== 'true') {
      return error('status.session_expired');
    }

    const resp = await executePromise(GetLinkToken(client_user_id), {
      loading: 'status.authenticating',
      success: (res) => {
        console.log('res.........', res);
        return res;
      },
    });

    if (!resp) {
      return error('status.backend_error');
    }

    const { expiration, link_token } = resp;
    console.log('resp:', resp);
    setLinkToken(link_token);
  };

  return (
    <Button
      className={classes.button}
      type="button"
      showLoader
      centered
      // checked={false}
      // animatedCaret
      small
      // icon={null}
      onClick={handleClick}
      flexContainerClassName={textClassName}
      // textClassName={textClassName}
    >
      {title}
    </Button>
  );
};

PlaidLink.displayName = 'Link';

export default PlaidLink;
