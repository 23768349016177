import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Notifier from '@knowins/notifier';

import theme from './theme';
import router from './router';

import { LoadingFullpage } from 'features/Loading';
// import { Alert } from '@knowins/notifier';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Suspense fallback={<LoadingFullpage />}>
            <QueryClientProvider client={queryClient}>
              <Notifier withThemeProvider={false} />
              {/* <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                preventDuplicate
                Components={{
                  info: Alert,
                  error: Alert,
                  success: Alert,
                  warning: Alert,
                  default: Alert,
                }}
              /> */}
              <RouterProvider router={router}></RouterProvider>
            </QueryClientProvider>
          </Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
