import { proxy } from 'valtio';

const state = proxy<{
  LEGACY_APP_LOADED: boolean;
  menu: string;
  wallet: string | undefined;
  modal: {
    [key: string]: any;
  };
  license: string;
  selected: string;
  open: boolean;
  authorization: Array<any>;
  legacy: {
    [key: string]: any;
  };
  stores: {
    [key: string]: any[];
  };
  views: {
    [key: string]: any;
  };
}>({
  LEGACY_APP_LOADED: false,
  menu: '[]',
  wallet: undefined, // '0x019bbe745b5c9b70060408Bf720B1E5172EEa5A3', //
  modal: {
    drawer: true,
    offer: false,
  },
  license: '{"LA": 0}',
  selected: '',
  open: false,
  authorization: [],
  legacy: {
    EXTD_JE: {},
    CUR: {
      update: false,
      data: [],
    },
    ADF: {
      update: false,
      data: [],
    },
    AND: {
      update: false,
      data: [],
    },
    ANC: {
      update: false,
      data: [],
    },
    LKC: {
      update: false,
      data: [],
    },
    NAD: {
      update: false,
      data: [],
    },
    COT: {
      update: false,
      data: [],
    },
    CUS: {
      update: false,
      data: [],
    },
    SUP: {
      update: false,
      data: [],
    },
    views: {},
  },
  stores: {},
  views: {},
});

export const initizlizeViewStore = (
  sid: string,
  options: { base: Array<string>; view: string; [key: string]: any }
) => {
  if (!state.legacy.views[sid]) {
    state.legacy.views[sid] = {};
  }
  const { base, view, ...others } = options;
  state.legacy.views[sid].data = {};
  state.legacy.views[sid]._translate = {};
  state.legacy.views[sid]._tbar = { ...others, view, base, mode: '' };
};

export const ADD = 'C';
export const VIEW = 'V';
export const EDIT = 'A';
export const SAVE = 'S';
export const COPY = 'O';

export const addView = 'Add';
export const viewView = 'View';
export const listView = 'List';
export const editView = 'Edit';
export const holdView = 'Hold';
export const cancelEdit = 'CancelEdit';
export const okCancel = 'OkCancel';

export const appViewStore = state.legacy.views;
export const initizlizeTbarViewStore = (
  sid: string,
  options: { base: Array<string>; view: string; [key: string]: any }
) => {
  if (!state.legacy.views[sid]) {
    state.legacy.views[sid] = {};
  }
  const { base, view, ...others } = options;
  state.legacy.views[sid].data = {};
  state.legacy.views[sid]._records = [];
  state.legacy.views[sid]._translate = {};
  state.legacy.views[sid]._tbar = { ...others, view, base, mode: '' };
};
export const setListView = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.view = listView;
  }
};
export const setEditView = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.view = editView;
  }
};
export const setViewView = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.view = viewView;
  }
};
export const setAddView = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.view = addView;
  }
};
export const setCancelEditView = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.view = cancelEdit;
  }
};

export const isOkCancelView = (sid: string) =>
  state.legacy.views[sid]?._tbar.view === okCancel;
export const isAddView = (sid: string) =>
  state.legacy.views[sid]?._tbar.view === addView;

export const setTbarMode = (sid: string, mode: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.mode = mode;
  }
};
export const setTbarAction = (sid: string, action: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.action = action;
  }
};
export const setTbarDisabled = (sid: string, disabled: boolean) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._tbar.disabled = disabled;
  }
};
export const setTbarViewData = (sid: string, data: any, prop?: string) => {
  if (state.legacy.views[sid]) {
    if (!prop) {
      state.legacy.views[sid].data = data;
    } else {
      state.legacy.views[sid].data[prop] = data;
    }
  }
};

export const addNewRecord = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid].data = {};
  }
};
export const saveLastState = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid].last = { ...state.legacy.views[sid].data };
  }
};
export const restoreLastState = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid].data = state.legacy.views[sid].last;
  }
};
export const setReadOnly = (sid: string, readonly: boolean) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid].readOnly = readonly;
  }
};
export const setRecords = (sid: string, records: Array<any>) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._records = records;
  }
};
export const createRecord = (sid: string, record: Array<any>) => {
  if (state.legacy.views[sid]) {
    // ...
  }
};export const insertRecord = (sid: string, record: Array<any>) => {
  if (state.legacy.views[sid]) {
    // ...
  }
};
export const updateRecord = (sid: string, record: Array<any>) => {
  if (state.legacy.views[sid]) {
    // ...
  }
};
export const deleteRecord = (sid: string, record: Array<any>) => {
  if (state.legacy.views[sid]) {
    // ...
  }
};
export const unselect = (sid: string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._selected = undefined;
    state.legacy.views[sid].data = {};
  }
};
export const select = (sid: string, selected: number | string) => {
  if (state.legacy.views[sid]) {
    state.legacy.views[sid]._selected = selected;
  }
};

export default state;
