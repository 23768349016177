import React from 'react';
import Plot from 'react-plotly.js';
import { ThemeProvider } from '@mui/material/styles';

import theme from 'theme';

const PlotChart = (props) => {
  const { data, layout, frame } = props;

  return (
    <ThemeProvider theme={theme}>
      <Plot
        data={data}
        layout={layout}
        frame={frame}
        config={{ displaylogo: false }}
      />
    </ThemeProvider>
  );
};

export default PlotChart;
