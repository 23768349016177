import { enact } from './util';
import LanguageControl from '../../../../components/Language/LanguageControl';
import { reloadApp } from 'helper/utils';
import { getOperationError } from 'helper/error';

const showLoading = (view) => {
  if (view && typeof view.setLoading === 'function') {
    view.setLoading(true);
  }
};

const hideLoading = (view) => {
  if (view && typeof view.setLoading === 'function') {
    view.setLoading(false);
  }
};

export const loadStoreAsync = (store, extraParams, vm, tbar) => {
  if (tbar) {
    enact(vm, tbar, false);
  }
  return new Promise((resolve, reject) => {
    const proxy = store.getProxy();
    if (proxy && extraParams) {
      proxy.setExtraParams(extraParams);
    }

    store.load((records, operation, success) => {
      if (tbar) {
        enact(vm, tbar, true);
      }
      if (success) {
        resolve(records);
      } else {
        const errMsg = operation.error;
        console.log(errMsg);
        reject(getOperationError(operation));
      }
    });
  });
};

export const loadViewStoreAsync = (view, store, extraParams, vm, tbar) => {
  showLoading(view);

  return loadStoreAsync(store, extraParams, vm, tbar)
    .then((records) => {
      return records;
    })
    .catch((err) => {
      console.log('........................', err);
      if (typeof err === 'string' && err === 'status.session_expired') {
        reloadApp();
      } else if (
        typeof err === 'string' &&
        err === 'Unable to connect to the server.'
      ) {
        // Return by the RemotingProvider.js
        throw 'status.gateway_timeout';
      }
      throw err;
    })
    .finally(() => {
      hideLoading(view);
    });
};

export const loadViewStoreWithParamsAsync = (view, store, options) => {
  return new Promise((resolve, reject) => {
    store.load({
      ...options,
      callback: function (records, operation, success) {
        if (success === true) {
          resolve(records, operation);
        } else {
          if (
            operation.request._proxy.reader.rawData &&
            operation.request._proxy.reader.rawData.title !== undefined &&
            operation.request._proxy.reader.rawData.message !== undefined
          ) {
            reject(operation.request._proxy.reader.rawData.message);
          } else {
            reject(
              LanguageControl.translate(
                'Unsuccessful operation. Please try again later'
              )
            );
          }
        }
      },
    });
  });
};

export const syncStoreAsync = (store, extraParams, vm, tbar) => {
  if (tbar) {
    enact(vm, tbar, false);
  }
  return new Promise((resolve, reject) => {
    const proxy = store.getProxy();
    if (proxy && extraParams) {
      store.getProxy().setExtraParams(extraParams);
    }

    store.sync({
      success: (batch, options) => {
        resolve(batch, options);
      },
      failure: (batch, options) => {
        reject(batch, options);
      },
      callback: function (batch, eOpts) {
        if (tbar) {
          enact(vm, tbar, true);
        }
      },
    });
  });
};

export const synchronizeViewStoreAsync = (view, store, callbackFn = null) => {
  return new Promise((resolve, reject) => {
    showLoading(view);
    // if (view && view.mask) {
    //   view.mask();
    //   view.setLoading(true);
    // }

    store.sync({
      success: function (batch, eOpts) {
        hideLoading(view);
        // if (view && view.unmask) {
        //   view.unmask();
        //   view.setLoading(false);
        // }
        console.log('resolve....batch');

        const operation = batch?.operations[0];

        resolve(operation?._response);
      },
      failure: function (batch, eOpts) {
        hideLoading(view);
        // if (view && view.unmask) {
        //   view.unmask();
        //   view.setLoading(false);
        // }
        if (batch.hasException() === true) {
          const error = batch.exceptions[0].error;
          if (error !== undefined) {
            if (typeof error === 'string' || typeof error === 'object') {
              // reject(LanguageControl.translate(error));
              resolve({
                success: false,
                message: error,
              });
            } else {
              const { status, statusText } = error;
              // reject(
              //   `${LanguageControl.translate(
              //     statusText
              //   )}. ${LanguageControl.translate('Error Code:')} ${status}`
              // );
              resolve({
                success: false,
                message: statusText,
              });
            }
          } else {
            // Note - Vle: ExtJS 5 Upgrade
            if (
              batch.exceptions[0]._proxy.reader.rawData.message !== undefined
            ) {
              // reject(
              //   LanguageControl.translate(
              //     batch.exceptions[0]._proxy.reader.rawData.message
              //   )
              // );
              resolve({
                success: false,
                message: batch.exceptions[0]._proxy.reader.rawData.message,
              });
            } else {
              resolve({
                success: false,
                message: 'status.operation_failed',
              });
            }
          }
        } else if (batch.operations.length > 0) {
          const operation = batch.operations[0];
          if (
            operation._response !== undefined &&
            operation._response.result !== undefined
          ) {
            if (operation._response.result.message !== undefined) {
              // reject(
              //   LanguageControl.translate(operation._response.result.message)
              // );
              resolve({
                success: false,
                message: operation._response.result.message,
              });
            } else {
              resolve({
                success: false,
                message: 'status.operation_panic',
              });
            }
          } else if (operation._resultSet.count === 0) {
            if (callbackFn) {
              resolve({
                success: false,
                message: 'status.connection_unexpected_error',
              });
            }
          } else if (
            operation._resultSet.title === undefined ||
            operation._resultSet.message !== undefined
          ) {
            resolve({
              success: false,
              message: 'status.operation_failed',
            });
          }
        } else {
          resolve({
            success: false,
            message: 'status.operation_failed',
          });
        }
      },
    });
  });
};
