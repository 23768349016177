Date.prototype.formatYYYYMMDD = function (time = '') {
  return (
    this.getFullYear() +
    '-' +
    Ext.String.leftPad(this.getMonth() + 1, 2, '0') +
    '-' +
    Ext.String.leftPad(this.getDate(), 2, '0') +
    time
  );
};

Date.prototype.formatSQLDate = function () {
  return (
    this.getFullYear() +
    '-' +
    `${this.getMonth() + 1}`.padStart(2, '0') +
    '-' +
    `${this.getDate()}`.padStart(2, '0') +
    ' ' +
    `${this.getHours()}`.padStart(2, '0') +
    ':' +
    `${this.getMinutes()}`.padStart(2, '0') +
    ':' +
    `${this.getSeconds()}`.padStart(2, '0')
  );
};

Date.prototype.addDays = function (days = 0) {
  return new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getDate() + days,
    this.getHours(),
    this.getMinutes(),
    this.getSeconds(),
    this.getMilliseconds()
  );
};

export const getFromDate = (from = '') => {
  const valueFrom = (from || '').trim();
  if (['T', 'TODAY'].includes(valueFrom.toUpperCase())) {
    return new Date().formatYYYYMMDD() + ' 00:00:00';
  }

  if (['NOW', 'CURRENT'].includes(valueFrom.toUpperCase())) {
    return new Date().formatSQLDate();
  }

  const re = /^T\s*([+-])\s*(\d+)$/i;
  const match = re.exec(valueFrom.toUpperCase());
  if (match && match[1] && !isNaN(match[2])) {
    const today = new Date();
    const d =
      match[1] === '+'
        ? today.addDays(parseInt(match[2]))
        : today.addDays(-1 * parseInt(match[2]));
    return d.formatYYYYMMDD() + ' 00:00:00';
  }

  return from;
};
// AppSidebarItem : Encountered two children with the same key, `NaN`. Keys should be unique so that components maintain their identity across updates. Non-unique keys may cause children to be duplicated and/or omitted — the behavior is unsupported and could change in a future version.

export const getToDate = (to = '') => {
  const valueTo = (to || '').trim();
  if (['T', 'TODAY'].includes(valueTo.toUpperCase())) {
    return new Date().formatYYYYMMDD() + ' 23:59:59';
  }

  if (['NOW', 'CURRENT'].includes(valueTo.toUpperCase())) {
    return new Date().formatSQLDate();
  }

  const re = /^T\s*([+-])\s*(\d+)$/i;
  const match = re.exec(valueTo.toUpperCase());

  if (match && match[1] && !isNaN(match[2])) {
    const today = new Date();
    const d =
      match[1] === '+'
        ? today.addDays(parseInt(match[2]))
        : today.addDays(-1 * parseInt(match[2]));
    return d.formatYYYYMMDD() + ' 23:59:59';
  }

  return to;
};

export function getPeriods(maxPeriod) {
  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1; // month indexed from zero

  if (month === maxPeriod) {
    month = 1;
    year++;
  } else {
    month++;
  }

  const periods = [];
  for (let j = 0; j < 15; j++) {
    for (let i = 0; i < maxPeriod; i++) {
      const period = month < 10 ? `00${month}` : `0${month}`;
      periods.push([`${year}${period}`, `${year}${period}`]);

      month--;
      if (month === 0) {
        month = maxPeriod;
        year--;
      }
    }
  }
  
  return periods;
}

export const PeriodAdd = (basePeriod, numberPeriods, maxPeriod) => {
  var re = /(^\d{4,4})(\d{3,3})\b/,
    arrPeriod = re.exec(basePeriod);

  if (arrPeriod === null) {
    return '';
  }

  var base =
      parseInt(arrPeriod[1], 10) * maxPeriod +
      parseInt(arrPeriod[2], 10) -
      1 +
      parseInt(numberPeriods, 10),
    p = (base % maxPeriod) + 1,
    y = Math.floor(base / maxPeriod);

  return ('0000' + String(y)).slice(-4) + ('000' + String(p)).slice(-3);
};

export const parsePeriod = (value, currentperiod, maxPeriod) => {
  var arrPeriod = /^([^0]\d{3})(\d{3})$/.exec(value);

  if (arrPeriod === null) {
    var arrValue = /^c(\+|-)?$|^c?(\+|-)(\d+)?$/i.exec(value);

    if (arrValue === null) {
      return false;
    }

    if (!arrValue[1] && !arrValue[2]) {
      return currentperiod;
    }

    if (arrValue[3]) {
      return PeriodAdd(
        currentperiod,
        (arrValue[1] || arrValue[2]) + arrValue[3],
        maxPeriod
      );
    } else {
      return PeriodAdd(
        currentperiod,
        (arrValue[1] || arrValue[2]) + 1,
        maxPeriod
      );
    }
  }

  return value;
};

export const DateAdd = (baseDate, numberPeriods, inMonthly) => {
  var resultDate = new Date(baseDate),
    numbers = parseInt(numberPeriods, 0);

  if (resultDate === undefined) {
    return '';
  } else {
    if (inMonthly === '') {
      resultDate.setDate(resultDate.getDate() + numbers);
    } else if (inMonthly == 'M') {
      resultDate.setMonth(resultDate.getMonth() + numbers);
    } else if (inMonthly == 'N') {
      resultDate.setMonth(resultDate.getMonth() + numbers);
      resultDate.setDate(Ext.Date.getLastDateOfMonth(resultDate).getDate());
    } else {
      return '';
    }

    return Ext.util.Format.date(resultDate, 'Y-m-d');
  }
};

export const periodBetween = (period, openperiodfrom, openperiodto) => {
  return period >= openperiodfrom && period <= openperiodto;
};

export const parseDate = (value) => {
  var arrValue = /^t(\+|-)?$|^t?(\+|-)(\d+)?$/i.exec(value);

  if (arrValue === null) {
    return value;
  }

  if (!arrValue[1] && !arrValue[2]) {
    return new Date();
  }

  if (arrValue[3]) {
    return Ext.Date.add(
      new Date(),
      Ext.Date.DAY,
      (arrValue[1] || arrValue[2]) + arrValue[3]
    );
  } else {
    return Ext.Date.add(
      new Date(),
      Ext.Date.DAY,
      (arrValue[1] || arrValue[2]) + 1
    );
  }
};

export const getDefaultPeriod = () => {
  const today = new Date();
  const mm = `00${today.getMonth() + 1}`;
  return `${today.getFullYear()}${mm.substr(mm.length - 3)}`;
};

export const formatSQLDate = (d) => {
  const yyyy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mm = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
  const dd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
  return `${yyyy}-${mm}-${dd}`;
};

export const initPeriodFilter = (fNode, currentperiod) => {
  const curr = currentperiod || getDefaultPeriod();
  const year = curr.substr(0, 4);

  if (fNode.get('filterfrom') == 'C') fNode.set('filterfrom', curr);
  else if (fNode.get('filterfrom') == 'S')
    fNode.set('filterfrom', year.toString() + '001');
  else if (fNode.get('filterfrom') == 'E')
    fNode.set('filterfrom', year.toString() + '012');

  if (fNode.get('filterto') == 'C') fNode.set('filterto', curr);
  else if (fNode.get('filterto') == 'S')
    fNode.set('filterto', year.toString() + '001');
  else if (fNode.get('filterto') == 'E')
    fNode.set('filterto', year.toString() + '012');

  return fNode;
};

export const initDateFilter = (fNode) => {
  var cur = new Date();

  if (fNode.get('filterfrom') == 'C')
    fNode.set('filterfrom', Ext.Date.format(cur, 'Y-m-d'));
  else if (fNode.get('filterfrom') == 'S')
    fNode.set('filterfrom', Ext.Date.format(cur, 'Y-m') + '-01');
  else if (fNode.get('filterfrom') == 'E')
    fNode.set(
      'filterfrom',
      Ext.Date.format(Ext.Date.getLastDateOfMonth(cur), 'Y-m-d')
    );

  if (fNode.get('filterto') == 'C')
    fNode.set('filterto', Ext.Date.format(cur, 'Y-m-d'));
  else if (fNode.get('filterto') == 'S')
    fNode.set('filterto', Ext.Date.format(cur, 'Y-m') + '-01');
  else if (fNode.get('filterto') == 'E')
    fNode.set(
      'filterto',
      Ext.Date.format(Ext.Date.getLastDateOfMonth(cur), 'Y-m-d')
    );

  return fNode;
};
