import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { makeStyles } from 'tss-react/mui';
import LanguageControl from './LanguageControl';
import { updateLocale } from './api';

const languageMap = {
  en: { label: 'English', dir: 'ltr', active: true },
  vn: { label: 'Tiếng Việt', dir: 'ltr', active: false },
};

const LanguageSelect = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [selected, setSelected] = useState(
    localStorage.getItem('language') || 'en'
  );
  const selection = languageMap[selected];

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  // React.useEffect(() => {
  //   document.body.dir = languageMap[selected].dir;
  // }, [menuAnchor, selected]);
  // console.log(languageMap, selected);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button
        className={classes.button}
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
      >
        {selection ? selection.label : 'English'}
        <ArrowDropDown fontSize="small" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div>
          <List>
            {Object.keys(languageMap)?.map((lang) => (
              <ListItem
                button
                fontSize="small"
                key={lang}
                onClick={() => {
                  console.log('Update the locale store');
                  updateLocale(lang).then(() => {
                    LanguageControl.fetch(lang).then(() => {
                      // Check if the Ext app is already loaded
                      if (window.MoneFox) {
                        MoneFox.controller.MainController.createCommonStores();
                      }
                    });
                    localStorage.setItem('language', lang);
                    i18next.changeLanguage(lang);
                    i18next.reloadResources();
                    setMenuAnchor(null);
                    setSelected(lang);
                  });
                }}
              >
                {languageMap[lang].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  button: {
    // marginLeft: theme.spacing(2),
    textTransform: 'none',
  },
}));

export default LanguageSelect;
