import { reloadApp } from './utils';

export const throwError = (err) => {
  if (typeof err === 'string' && err === 'status.session_expired') {
    reloadApp();
  } else if (
    typeof err === 'string' &&
    err === 'Unable to connect to the server.'
  ) {
    // Return by the RemotingProvider.js
    throw 'status.gateway_timeout';
  }

  throw err;
};

export const getOperationError = (operation) => {
  if (operation.hasException() === true) {
    if (operation.error !== undefined) {
      return operation.error;
    }
  } else if (
    operation.request._proxy.reader.rawData &&
    operation.request._proxy.reader.rawData.title !== undefined &&
    operation.request._proxy.reader.rawData.message !== undefined
  ) {
    return operation.request._proxy.reader.rawData.message;
  } else {
    return 'Unsuccessful operation. Please try again later';
  }
};

export const getResponseError = (response) => {
  return response.data?.result?.message || response.data?.message;
};

export const getError = (e) => {
  let errMsg;

  if (e === undefined || e === null) {
    return 'Unknown Error';
  }

  if (typeof e === 'string') {
    return e;
  }

  if (e.status === 500) {
    return e.message || e.statusText;
  }

  if (e.code === -32603) {
    if (e.data && typeof e.data.message === 'string') {
      errMsg = e.data.message;
    } else if (typeof e.message === 'string') {
      errMsg = e.message;
    }
    if (errMsg.indexOf('Paymaster balance might not be enough') !== -1) {
      return errMsg;
    }
  }

  // if (typeof e === 'string') {
  //   errMsg = e;
  // }

  if (e.error && typeof e.error.message === 'string') {
    errMsg = e.error.message;
  }

  if (e.message && typeof e.message === 'string') {
    errMsg = e.message;
  }

  if (e.reason && typeof e.reason === 'string') {
    errMsg = e.reason;
    if (
      errMsg.indexOf(
        'execution reverted: ERC20: transfer amount exceeds balance'
      ) === 0
    ) {
      return errMsg;
    }

    if (errMsg.indexOf('execution reverted: not enough allowance') === 0) {
      return errMsg;
    }
  }

  if (e.error && e.error.toString()) {
    errMsg = e.error.toString();
  }

  return errMsg;
};
