import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useGetCurrencyQuery } from 'features/FrmUtils';
import { useTranslation } from 'react-i18next';

// const CurrencySelect: React.FC<
//   FormControlProps & {
//     control?: Control;
//     name: string;
//     defaultValue: any;
//     selectProps?: SelectProps;
//   }
// > = ({ control, selectProps, name, defaultValue, ...props }) => {
const CurrencySelect = React.forwardRef<{ o: any }, any>(
  (
    {
      onChange,
      onBlur,
      name,
      value,
      defaultValue,
      label,
      selectProps,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    // TODO: show loading
    const { data: currencies = [] } = useGetCurrencyQuery();

    console.log('Currency default value', defaultValue);
    // const selectEl = useMemo(
    //   () => (
    //     <Select
    //       className={classes.select}
    //       name={name}
    //       id="currency-select"
    //       {...selectProps}
    //     >
    //       {currencies.map((currency) => (
    //         <MenuItem
    //           dense
    //           key={currency.currencybaseid}
    //           value={currency.currencybaseid}
    //         >
    //           {currency.currencyalias}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   ),
    //   [currencies, selectProps, classes, name]
    // );

    return (
      <FormControl variant="standard" size="small" fullWidth {...others}>
        <InputLabel id="currency-select-label" size="small">
          {t('currencies.title')}
        </InputLabel>
        <Select
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          id="currency-select"
          labelId="currency-select-label"
          size="small"
          {...selectProps}
        >
          {currencies.map((c) => (
            <MenuItem key={c.currencybaseid} value={c.currencybaseid} dense>
              {c.currencyalias}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    // return (
    //   <FormControl variant="standard" size="small" {...props}>
    //     <InputLabel style={{ textAlign: 'left' }} htmlFor="currency-select">
    //       {t('currencies.title')}
    //     </InputLabel>
    //     {control ? (
    //       <Controller
    //         as={selectEl}
    //         name={name}
    //         control={control}
    //         defaultValue={defaultValue}
    //         size="small"
    //       />
    //     ) : (
    //       selectEl
    //     )}
    //   </FormControl>
    // );
  }
);

CurrencySelect.displayName = 'CurrencySelect';
export default CurrencySelect;
