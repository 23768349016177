import { fetchLocal, loadLocale } from './api';
class LanguageControl {
  static lang = {};
  static store = null;

  constructor() {}

  static addValue(key, translation) {
    this.lang[key] = translation;
  }

  static translate(key) {
    if (!key) {
      return ' ';
    }

    key = key.trim();
    if (key.length === 0) {
      return ' ';
    }

    if (this.lang[key] !== undefined) {
      return this.lang[key];
    } else {
      const langObj = {};
      this.lang[key] = key; // NOTED: This value may NOT be updated IN TIME
      langObj[key] = key;

      loadLocale(langObj).then((records) => {
        if (records) {
          records.forEach((r) => {
            this.addValue(r.KEY, r.TRANSLATION);
          });
        }
      });
    }

    return this.lang[key];
  }

  static async fetch(lang) {
    return await fetchLocal(lang).then((records) => {
      if (records) {
        records.forEach((r) => {
          this.addValue(r.KEY, r.TRANSLATION);
        });
      }
    });
  }
}

export default LanguageControl;
