import { createBrowserRouter, Outlet } from 'react-router-dom';

import { AppRoot } from 'features/App';
import { Home, HomeLayout } from './features/Home';
import { ErrorPage } from 'features/Error';
import { Support } from 'features/Support';
import { Register } from 'features/Register';
import { Login, ForgotPassword, ChangePassword } from 'features/Login';

const Root = () => {
  return (
    <>
      <HomeLayout>
        <Outlet />
      </HomeLayout>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: '/app',
    element: <AppRoot />,
  },
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    //loader: RootLoader,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/sign-up',
        element: <Register />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/change-password/:token',
        element: <ChangePassword />,
      },
      {
        path: '/support',
        element: <Support />,
      },
    ],
  },
]);

export default router;
