// @ts-nocheck
import { useEffect, useState, lazy, memo } from 'react';
import { Navigate } from 'react-router-dom';

import AppLayout from './AppLayout';
import AppWizard from './AppWizard';
import AppAd from './AppAd';
import AppChat from './AppChat';
import { useLoginStatus } from 'api';
import { LoadingFullpage } from 'features/Loading';
import { AppWindow } from 'features/AppWindow';

let count = 0;

const AppLoader = lazy(() => import('../App/AppLoader'));

const Main = () => {
  console.log('render #:.......', ++count);

  return (
    <AppLoader>
      <AppLayout>
        <AppAd />
        <AppWindow />
        <AppWizard />
        <AppChat />
      </AppLayout>
    </AppLoader>
  );
};

const AppRoot = () => {
  const { data: config, status: statusConfig } = useLoginStatus();

  console.log('config, statusConfig:.......', config, statusConfig);

  // if (statusConfig !== 'success') {
  //   return <LoadingFullpage />;
  // }

  // if (!config?.login) {
  //   return <Navigate to="/login" />;
  // }

  return (
    <>
      {statusConfig != 'success' && <LoadingFullpage />}
      {statusConfig == 'success' && !config?.login && <Navigate to="/login" />}
      {statusConfig == 'success' && config?.login && <Main />}
    </>
  );
};

export default AppRoot;
