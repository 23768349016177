import React from 'react';
import { createRoot } from 'react-dom/client';
import axios from 'redaxios';
import { createExtWindow } from 'store/windowStore';
import Command from 'constant/command';
import CONFIG from 'constant/common';
import LanguageControl from 'components/Language/LanguageControl';
import { loadStoreAsync } from 'legacy/classic/src/utils/data';
import { error, info, warn, executePromise } from '@knowins/notifier';
import { runReportFromJSON } from './data';
import { getResponseError, getError } from './error';
import Chart from 'components/Chart/Plot';
import { createButton } from 'components/Button';
import { transformChartData, convertChartRawData } from './chart';
import { checkSessionTimeout } from 'api/proxy';
import { formReportChart } from 'api/repository';
import appStore from 'store/appStore';
import * as XLSX from 'xlsx';

export const getJSONFromQuery = (query) => {
  try {
    const re = /^QUERY_DB\s*\(\s*(\s*\{(.|\s)*\})\s*,\s*([\w.]*)\s*\)$/i;
    const match = re.exec(query);
    if (!match) {
      throw 'status.bad_fomular';
    }
    const reportJson = match[1];
    const reportCode = match[3];

    const reportInput = JSON.parse(reportJson);
    const { from: datasource, select: outputs, where: filters } = reportInput;

    return {
      datasource,
      filters,
      outputs,
      reportcode: reportCode,
    };
  } catch (e) {
    error('status.bad_json_data');
  }
};

export const fixClickableNode = (dom, tbar, charts = [], refresh) => {
  let count = 0;
  let titleNodes;
  const t = setInterval(() => {
    if (!dom) {
      // For the sake of safe execution
      if (t) {
        clearInterval(t);
      }
      return;
    }

    titleNodes = dom.querySelectorAll('[title]');
    if (titleNodes.length === 0 && count < 120) {
      count++;
    } else {
      clearInterval(t);
    }

    if (titleNodes.length > 0) {
      titleNodes.forEach((n) => {
        // Fix clickable: DRILLDONW, REPORT
        n.style.cursor = 'pointer';

        // Santinize the title
        let title = n.getAttribute('title');
        title = title.replace(/[\n\t\r]/g, '').trim();
        n.setAttribute('title', title);

        // Fix image tag: IMAGE({ "src": "http:\\..." })
        let re = /^IMAGE\s*\(((.|\s)*)\)\s*/i;
        if (re.test(title)) {
          re = /^IMAGE\s*\(\s*(\{(.|\s)*\})\s*\)\s*$/i;
          const match = re.exec(title);
          if (!match || !match[1]) {
            warn('status.invalid_image_formular');
          } else {
            const inputs = match[1];
            if (inputs) {
              try {
                const data = JSON.parse(inputs);
                const opacity = data.opacity ? `opacity=${data.opacity}` : '';
                const filter = data.filter ? `filter=${data.filter}` : '';
                const border = data.border ? `border=${data.border}` : '';
                const border_radius = data.border_radius
                  ? `border=${data.border_radius}`
                  : '';

                const maxWidth = n.clientWidth;
                const maxHeight = n.clientHeight;
                n.innerHTML = `<image src='${data.src}' max-width='${n.clientWidth}px' max-height='${n.clientHeight}px' ${opacity} ${filter} ${border} ${border_radius}/>`;
                // Somehow max-height doesn't work
                const img = n.getElementsByTagName('img')[0];
                img.style.maxWidth = `${maxWidth}px`;
                img.style.maxHeight = `${maxHeight}px`;

                n.removeAttribute('title');
              } catch (e) {
                warn('status.invalid_image_formular');
              }
            }
          }
        }

        // Fix href tag: LINKTO({ "src": "http:\\..." })
        re = /^LINKTO.*/i;
        if (re.test(title)) {
          re = /^LINKTO\s*\(\s*([\/:.\w_-]+)\s*\)\s*/i; //eslint-disable-line
          const match = re.exec(title);
          if (!match || !match[1]) {
            warn('status.invalid_linkto_formular');
          } else {
            const href = match[1];
            if (href) {
              n.innerHTML = `<a href='${href}' target="_blank" rel="nofollow noopener noreferrer">${n.innerText}</a>`;
              n.removeAttribute('title');
            }
          }
        }

        re = /^DRILLDOWN\s*\((.|\s)*/i;
        if (re.test(title)) {
          n.removeAttribute('title');
          n.setAttribute('data-title', title);
        }

        re = /^CHART\s*:\s?([\w._-]*)/i;
        if (re.test(title)) {
          const match = re.exec(title);
          if (!match || !match[1]) {
            warn('status.invalid_chart_formular');
          } else {
            const chartid = match[1];
            const chart = charts.find((c) => c.chartid === chartid);
            const maxWidth = n.clientWidth;
            const maxHeight = n.clientHeight;
            if (chart && chart.chartconfig?.traces) {
              const {
                layout = [],
                frame = [],
                traces = [{}],
              } = chart.chartconfig || {};
              const data = [];
              for (let i = 0; i < traces.length; i++) {
                const { meta, ...figures } = traces[i];
                const chartData = convertChartRawData(chart.data);
                const transform = transformChartData(meta, chartData);
                data.push({
                  ...figures,
                  ...transform,
                  meta,
                });
              }

              const placeholder = n;
              const root = createRoot(placeholder);
              const ReactElement = React.createElement;
              root.render(
                ReactElement(Chart, {
                  data,
                  layout: {
                    ...layout,
                    width: maxWidth,
                    height: maxHeight,
                  },
                  frame,
                })
              );

              n.removeAttribute('title');
              n.setAttribute('data-title', title);
            }
          }
        }

        // Let's try to create a button, if any
        const { moneta } = readLocalStorage();
        createButton(
          moneta,
          n,
          title,
          run,
          refresh,
          { getView: () => ({ el: { dom } }) },
          tbar
        );
      });
    }
  }, 1000);
};

export const run = (command, vm, tbar) => {
  return new Promise((resolve, reject) => {
    const { action, fn, data = {} } = command;
    const { _key, _text, ...fields } = data;
    const records = [];
    let re;
    let query; //  = command.query.replace(/[\n\t\r]/g, '').trim();

    switch (action) {
      case Command.DRILLDOWN:
        query = command.query.replace(/[\n\t\r]/g, '').trim();
        re = /^QUERY_DB[\s\t]*\(((.|\n|\r)*)(\)|\)[\s,\t]*)$/i;
        if (re.test(query)) {
          const ds = Ext.Factory.store({
            model: 'MoneFox.model.ReportDrillDown',
          });
          executePromise(loadStoreAsync(ds, { fomular: query }, vm, tbar), {
            loading: 'status.running_report',
            success: (records) => {
              const record = records[0];
              createExtWindow(undefined, {
                xtype: 'reportviewerwdrawdata',
                xclass: 'MoneFox.view.rp.reportviewer.WdRawData',
                text: 'Insights: ' + JSON.stringify(record.data.rows[0]),
                drillDown: true,
                viewModel: {
                  data: {
                    record,
                  },
                },
              });
              return '';
            },
          });
        } else {
          error('status.wrong_query_prefix');
        }
        break;
      case Command.IMPORT:
        switch (fn.toUpperCase()) {
          case 'JNE':
          case 'JENTRY':
            data.forEach((d) => {
              records.push(new MoneFox.model.LaTransaction(d));
            });

            if (records.length == 0) {
              reject('status.no_data');
            }

            createExtWindow(undefined, {
              xtype: 'lajournalentrypanel',
              xclass: 'MoneFox.view.la.journal.LaJournalEntryPanel',
              title: LanguageControl.translate('Journal Entry'),
              height: 800,
              width: 1200,
              options: {
                action: 'PICKUP',
                records,
                landing: 'gridview',
                data: {
                  journaltype: records[0].data.journaltype,
                },
              },
              onAction: (action, data) => {
                if (action === 'saved') {
                  resolve(data);
                } else {
                  reject(data);
                }
              },
            });
            break;
          case 'JNI':
            // Directly import to the ledger
            executePromise(
              axios
                .post('/api/router.php', {
                  action: 'FrmLaJournalEntry',
                  method: 'importRecords',
                  data: [
                    data.map((d) => ({
                      ...d,
                      posting: 'BU',
                      inuseflag: 'Y',
                    })),
                  ],
                  type: 'rpc',
                })
                .then(checkSessionTimeout)
                .then((res) => {
                  if (!res.data?.result?.success) {
                    reject(getResponseError(res));
                    return error(getResponseError(res));
                  }
                  resolve(res.data.result);
                  return res.data.result;
                })
                .catch((e) => {
                  reject(getError(e));
                }),
              {
                loading: 'status.updating_ledger',
              }
            );
            break;
          case 'JIQ':
          case 'JINQUIRY':
          case 'JNQ':
          case 'JQUERY':
            // Directly post to the ledger
            executePromise(
              axios
                .post('/api/router.php', {
                  action: 'FrmLaJournalEntry',
                  method: 'post',
                  data: [
                    data.map((d) => ({
                      ...d,
                      posting: 'BU',
                      inuseflag: 'Y',
                    })),
                  ],
                  type: 'rpc',
                })
                .then(checkSessionTimeout)
                .then((res) => {
                  if (!res.data?.result?.success) {
                    reject(getResponseError(res));
                    return error(getResponseError(res));
                  }
                  resolve(res.data.result);
                  return res.data.result;
                })
                .catch((e) => {
                  reject(getError(e));
                }),
              {
                loading: 'status.updating_ledger',
              }
            );
            break;
          default:
            // Directly import
            executePromise(
              axios
                .post('/api/router.php', {
                  action: CONFIG.SHORT_COMMANDS[fn.toUpperCase()] || fn,
                  method: 'importRecords',
                  data: [{ data }],
                  type: 'rpc',
                })
                .then(checkSessionTimeout)
                .then((res) => {
                  if (!res.data?.result?.success) {
                    reject(getResponseError(res));
                    return error(getResponseError(res));
                  }
                  resolve(res.data.result);
                  return res.data.result;
                })
                .catch((e) => {
                  reject(getError(e));
                }),
              {
                loading: 'status.updating',
              }
            );
        }
        break;
      case Command.REPORT:
        query = command.query.replace(/[\n\t\r]/g, '').trim();
        re = /^QUERY_DB[\s\t]*\(((.|\n|\r)*)(\)|\)[\s,\t]*)$/i;
        if (re.test(query)) {
          const json = getJSONFromQuery(query);
          const { name, owner } = data;
          if (json) {
            json.name = name;
            json.owner = owner;

            // Get report's charts
            executePromise(
              formReportChart('get', { selectedReportCode: json.reportcode }),
              {
                loading: 'status.loading',
                success: (res) => {
                  console.log('chart data.....', res);
                  if (res.success) {
                    json.charts = res.charts;
                    runReportFromJSON(json, json.reportcode || 'Report');
                  }
                  return null;
                },
              }
            );
          }
        } else {
          error('status.wrong_query_prefix');
        }
        break;
      case Command.OPEN:
        switch (fn) {
          case 'JNE':
            createExtWindow(undefined, {
              xtype: 'lajournalentrypanel',
              xclass: 'MoneFox.view.la.journal.LaJournalEntryPanel',
              title: LanguageControl.translate('Journal Entry'),
              options: {
                action: 'RUN',
                defaultledger: data.defaultledger || 'A',
                data: {
                  ...fields,
                  journaltype: _key,
                },
              },
            });
            break;
          case 'RPT':
            safeExecute(() =>
              createExtWindow(
                undefined,
                {
                  xtype: 'reportviewercontroller',
                  xclass: 'MoneFox.view.rp.reportviewer.ReportViewerPanel',
                  text: _text || _key,
                  drillDown: true,
                  viewModel: {
                    data: {
                      reportCode: _key,
                    },
                  },
                },
                null,
                false
              )
            );
            break;
          default:
            if (!CONFIG.CMD[fn]) {
              error('status.invalid_formula');
            } else {
              createExtWindow(undefined, {
                xtype: CONFIG.CMD[fn]['xtype'],
                xclass: CONFIG.CMD[fn]['xclass'],
                title: LanguageControl.translate(CONFIG.CMD[fn]['title']),
                // options: {
                //   action: 'RUN',
                //   defaultledger: data.defaultledger || 'A',
                //   data: {
                //     journaltype: _key,
                //   },
                // },
              });
            }
        }
        break;
      case Command.INVOCING:
        switch (fn) {
          case 'CREATE':
            return executePromise(
              axios
                .post('/api/router.php', {
                  action: 'FrmLaJournalEntry',
                  method: 'invoicing',
                  data: [data],
                  type: 'rpc',
                })
                .then(checkSessionTimeout)
                .then((res) => {
                  if (!res.data?.result?.success) {
                    reject(getResponseError(res));
                    return error(getResponseError(res));
                  }
                  resolve(res.data.result);
                  return res.data.result;
                })
                .catch((e) => {
                  reject(getError(e));
                }),
              {
                loading: 'status.updating_ledger',
              }
            );
          default:
        }
        break;
      default:
    }
  });
};

export const runImportCommand = (view, vm, tbar, title) => {
  const titles = title.split('&');
  const params = titles.map((t) => {
    const cmd = t.trim();

    let re = /^IMPORT\s*\(([^)]*)\)$/i;
    const match = re.exec(cmd);
    if (!match) {
      error('status.wrong_import_formula');
      return null;
    } else {
      // Get QUERY_DB group
      const params = match[1];
      let arr = params.split(',');
      if (arr.length < 3) {
        arr = params.split(';');
        if (arr.length < 3) {
          error('status.invalid_formula');
          return null;
        }
      }

      const [_1, _2, ...headers] = arr;
      const fn = _1.trim();
      const dataTag = _2.trim();

      if (!fn || !headers || !dataTag) {
        error('status.invalid_formula');
        return null;
      } else if (view && view.el && view.el.dom) {
        const dom = view.el.dom;
        const raw = dom.querySelector(`[title="${dataTag}"]`);
        if (!raw) {
          error('status.import_data_tag_not_found');
          return null;
        } else {
          // const header = others.replace(/\s/g, '').substring(1);
          // const headers = header.split(',');
          const len = headers.length;
          if (len === 0) {
            error('status.import_data_columns_not_found');
            return null;
          } else {
            const fields = headers.map((d) => d.trim());
            if (raw.innerText === '#N/A') {
              error('status.import_data_NA');
              return null;
            } else if (raw.innerText === '#VALUE!') {
              error('status.import_data_VALUE');
              return null;
            }

            const raws = raw.innerText.split('|');

            let count = -1;
            let data = [];
            let skipRow = false;
            let emptyRow = false;
            for (let j = 0; j < raws.length; j++) {
              if (j % len === 0) {
                // Next line
                if (skipRow || emptyRow) {
                  skipRow = false;
                } else {
                  count++;
                }
                data[count] = {};
                emptyRow = true;
              }

              // Check if this column is skipped
              const field = fields[j % len];
              const value = raws[j].trim();
              if (
                field &&
                !['skip', '__skip__', 'ignore', '__ignore__'].includes(
                  field.toLowerCase()
                )
              ) {
                data[count][field] = value;
                if (value != '') {
                  emptyRow = false;
                }
              }

              // Check if this row is skipped
              if (
                value &&
                ['_skip_', '__skip__'].includes(value.toLowerCase())
              ) {
                skipRow = true;
              }
            }

            // Case where the last row is skipped
            if (skipRow || emptyRow) {
              data.splice(-1, 1); // Remove the last row
            }

            // Make sure the mandantory fields
            if (
              [
                'JNI',
                'JNE',
                'JENTRY',
                'JIQ',
                'JINQUIRY',
                'JNQ',
                'JQUERY',
              ].includes(fn)
            ) {
              if (!fields.includes('journalno')) {
                // Case: newly insert journal
                data = data.map((d) => ({
                  ...d,
                  journalno: -1,
                }));

                if (!fields.includes('journalline')) {
                  // Case: newly insert journal
                  data = data.map((d, idx) => ({
                    ...d,
                    journalline: idx + 1,
                  }));
                }
              }
            }

            return { action: Command.IMPORT, fn, data };
          }
        }
      }
    }
  });

  if (params.length === 1) {
    return run(params[0], vm, tbar);
  } else if (params.length > 1) {
    try {
      return params.reduce(async (acc, curr) => {
        if (acc.length > 0) {
          info('status.running_next_task');
        }
        return [...(await acc), await run(curr, vm, tbar)];
      }, []);
    } catch (err) {
      // Will catch failure of first failed promise
      console.log('Failed:', err);
      error('status.import_skip_next_action');
    }
  }
};

export const runInvoiceCommand = (view, vm, tbar, title) => {
  // The are the case with mutiple invoicing commands running
  // INVOICING(EASYINVOICE,CREATE,INFO,PRODUCTS,Code,No,Feature..) & INVOICING(EASYINVOICE,CREATE,INFO,PRODUCTS,Code,No,Feature..)
  const titles = title.split('&');
  const params = titles.map((t) => {
    const cmd = t.trim();

    const re = /^INVOICING\s*\(([^)]*)\)$/i;
    const match = re.exec(cmd);
    if (!match) {
      error('status.wrong_invocing_formula');
      return null;
    } else {
      // Get QUERY_DB group
      const params = match[1];
      let arr = params.split(',');
      if (arr.length < 3) {
        arr = params.split(';');
        if (arr.length < 3) {
          error('status.invalid_formula');
          return null;
        }
      }

      const [_1, _2, _3, _4, ...headers] = arr;
      const provider = _1.trim();
      const fn = _2.trim();
      const infoTag = _3.trim();
      const productTag = _4.trim();
      const info = {};

      if (!fn || !infoTag) {
        error('status.invalid_formula');
        return null;
      } else if (view && view.el && view.el.dom) {
        const dom = view.el.dom;
        const raw = dom.querySelector(`[title="${infoTag}"]`);
        if (!raw) {
          error('status.invocing_info_tag_not_found');
          return null;
        } else {
          const arr = raw.innerText?.split('|');
          arr.forEach((v) => {
            const [key, val] = v.split(':');
            if (key !== undefined && val !== undefined) {
              info[key] = val;
            }
          });
        }
      }

      if (!fn || !headers || !productTag) {
        error('status.invalid_formula');
        return null;
      } else if (view && view.el && view.el.dom) {
        const dom = view.el.dom;
        const raw = dom.querySelector(`[title="${productTag}"]`);
        if (!raw) {
          error('status.import_data_tag_not_found');
          return null;
        } else {
          // const header = others.replace(/\s/g, '').substring(1);
          // const headers = header.split(',');
          const len = headers.length;
          if (len === 0) {
            error('status.import_data_columns_not_found');
            return null;
          } else {
            const fields = headers.map((d) => d.trim());
            if (raw.innerText === '#N/A') {
              error('status.import_data_NA');
              return null;
            } else if (raw.innerText === '#VALUE!') {
              error('status.import_data_VALUE');
              return null;
            }

            const raws = raw.innerText.split('|');

            let count = -1;
            let data = [];
            let skipRow = false;
            let emptyRow = false;
            for (let j = 0; j < raws.length; j++) {
              if (j % len === 0) {
                // Next line
                if (skipRow || emptyRow) {
                  skipRow = false;
                } else {
                  count++;
                }
                data[count] = {};
                emptyRow = true;
              }

              // Check if this column is skipped
              const field = fields[j % len];
              const value = raws[j].trim();
              if (
                field &&
                !['skip', '__skip__', 'ignore', '__ignore__'].includes(
                  field.toLowerCase()
                )
              ) {
                data[count][field] = value;
                if (value != '') {
                  emptyRow = false;
                }
              }

              // Check if this row is skipped
              if (
                value &&
                ['_skip_', '__skip__'].includes(value.toLowerCase())
              ) {
                skipRow = true;
              }
            }

            // Case where the last row is skipped
            if (skipRow || emptyRow) {
              data.splice(-1, 1); // Remove the last row
            }

            return {
              action: Command.INVOCING,
              fn,
              data: { provider, info, data },
            };
          }
        }
      }
    }
  });

  if (params.length === 1) {
    return run(params[0], vm, tbar);
  } else if (params.length > 1) {
    try {
      return params.reduce(async (acc, curr) => {
        if (acc.length > 0) {
          info('status.running_next_task');
        }
        return [...(await acc), await run(curr, vm, tbar)];
      }, []);
    } catch (err) {
      // Will catch failure of first failed promise
      console.log('Failed:', err);
      error('status.invocing_skip_next_action');
    }
  }
};

export const runUserCommand = (vm, tbar, el) => {
  const view = vm?.getView();

  // Investigate: This happens when running report from Data View
  let text;
  let title = el.getAttribute('title');
  if (!title) {
    title = el.getAttribute('data-title') || el.getAttribute('data-params');
    text = el.getAttribute('data-text');

    // This happens when running report from report Viewer
    if (!title) {
      // console.log('Searching for title in the parent element');
      title = el.parentNode.getAttribute('title');
      if (!title) {
        title = el.parentNode.getAttribute('data-title');
        text = el.parentNode.getAttribute('data-text');
      }
    }
  }

  if (title && title.length > 0) {
    title = title.replace(/[\n\t\r]/g, '').trim();
    // title = title.replace(/^(\r\n|\r|\n)+|(\r\n|\r|\n)+$/gm, '');
    // title = title.replace(/^\s+|\s+$/gm, '');

    // Check if it is DRILLDOWN formula
    let re = /^(\s|\t)*DRILLDOWN[\s\t]*\((.|\n|\r)*/;
    if (re.test(title)) {
      re = /^(\s|\t)*DRILLDOWN[\s\t]*\(((.|\n|\r)*)(\)|\)[\s,\t]*)$/i;
      const match = re.exec(title);
      if (!match || !match[2]) {
        error('status.wrong_drilldown_formula');
      } else {
        // Get QUERY_DB group
        const query = match[2];
        return run({ action: Command.DRILLDOWN, query }, vm, tbar);
      }
    }

    // Check if it is JOURNAL formula
    re = /^IMPORT\s*\([\s\w,;&()]+\)$/i;
    if (re.test(title)) {
      return runImportCommand(view, vm, tbar, title);

      // const titles = title.split('&');
      // const params = titles.map((t) => {
      //   const cmd = t.trim();

      //   re = /^IMPORT\s*\(([^)]*)\)$/i;
      //   const match = re.exec(cmd);
      //   if (!match) {
      //     error('status.wrong_import_formula');
      //     return null;
      //   } else {
      //     // Get QUERY_DB group
      //     const params = match[1];
      //     let arr = params.split(',');
      //     if (arr.length < 3) {
      //       arr = params.split(';');
      //       if (arr.length < 3) {
      //         error('status.invalid_formula');
      //         return null;
      //       }
      //     }

      //     const [_1, _2, ...headers] = arr;
      //     const fn = _1.trim();
      //     const dataTag = _2.trim();

      //     if (!fn || !headers || !dataTag) {
      //       error('status.invalid_formula');
      //       return null;
      //     } else if (view && view.el && view.el.dom) {
      //       const dom = view.el.dom;
      //       const raw = dom.querySelector(`[title="${dataTag}"]`);
      //       if (!raw) {
      //         error('status.import_data_tag_not_found');
      //         return null;
      //       } else {
      //         // const header = others.replace(/\s/g, '').substring(1);
      //         // const headers = header.split(',');
      //         const len = headers.length;
      //         if (len === 0) {
      //           error('status.import_data_columns_not_found');
      //           return null;
      //         } else {
      //           const fields = headers.map((d) => d.trim());
      //           if (raw.innerText === '#N/A') {
      //             error('status.import_data_NA');
      //             return null;
      //           } else if (raw.innerText === '#VALUE!') {
      //             error('status.import_data_VALUE');
      //             return null;
      //           }

      //           const raws = raw.innerText.split('|');

      //           let count = -1;
      //           let data = [];
      //           let skipRow = false;
      //           let emptyRow = false;
      //           for (let j = 0; j < raws.length; j++) {
      //             if (j % len === 0) {
      //               // Next line
      //               if (skipRow || emptyRow) {
      //                 skipRow = false;
      //               } else {
      //                 count++;
      //               }
      //               data[count] = {};
      //               emptyRow = true;
      //             }

      //             // Check if this column is skipped
      //             const field = fields[j % len];
      //             const value = raws[j].trim();
      //             if (
      //               field &&
      //               !['skip', '__skip__', 'ignore', '__ignore__'].includes(
      //                 field.toLowerCase()
      //               )
      //             ) {
      //               data[count][field] = value;
      //               if (value != '') {
      //                 emptyRow = false;
      //               }
      //             }

      //             // Check if this row is skipped
      //             if (
      //               value &&
      //               ['_skip_', '__skip__'].includes(value.toLowerCase())
      //             ) {
      //               skipRow = true;
      //             }
      //           }

      //           // Case where the last row is skipped
      //           if (skipRow || emptyRow) {
      //             data.splice(-1, 1); // Remove the last row
      //           }

      //           // Make sure the mandantory fields
      //           if (
      //             [
      //               'JNI',
      //               'JNE',
      //               'JENTRY',
      //               'JIQ',
      //               'JINQUIRY',
      //               'JNQ',
      //               'JQUERY',
      //             ].includes(fn)
      //           ) {
      //             if (!fields.includes('journalno')) {
      //               // Case: newly insert journal
      //               data = data.map((d) => ({
      //                 ...d,
      //                 journalno: -1,
      //               }));

      //               if (!fields.includes('journalline')) {
      //                 // Case: newly insert journal
      //                 data = data.map((d, idx) => ({
      //                   ...d,
      //                   journalline: idx + 1,
      //                 }));
      //               }
      //             }
      //           }

      //           return { action: Command.IMPORT, fn, data };
      //         }
      //       }
      //     }
      //   }
      // });

      // if (params.length === 1) {
      //   return run(params[0], vm, tbar);
      // } else if (params.length > 1) {
      //   try {
      //     return params.reduce(async (acc, curr) => {
      //       if (acc.length > 0) {
      //         info('status.running_next_task');
      //       }
      //       return [...(await acc), await run(curr, vm, tbar)];
      //     }, []);
      //   } catch (err) {
      //     // Will catch failure of first failed promise
      //     console.log('Failed:', err);
      //     error('status.import_skip_next_action');
      //   }
      // }
    }

    // Check if it is REPORT formula
    // re = /^\s*REPORT\s*\((.|\s)*/i;
    // if (re.test(title)) {
    //   re = /^(\s|\t)*REPORT[\s\t]*\(((.|\n|\r)*)(\)|\)[\s,\t]*)$/i;
    //   let match = re.exec(title);
    //   if (!match || !match[2]) {
    //     error('status.wrong_report_formula');
    //   } else {
    //     // Get QUERY_DB group
    //     const query = match[2];
    //     const { moneta } = readLocalStorage();
    //     return run(
    //       {
    //         action: Command.REPORT,
    //         query,
    //         data: { name: 'Report', owner: moneta },
    //       },
    //       vm,
    //       tbar
    //     );
    //   }
    // }

    // re = /^OPEN.*/i;
    // if (re.test(title)) {
    //   re = /^OPEN\s*\(\s*([\w\s\:\.,_-]+)\s*\)$/i; //eslint-disable-line
    //   const match = re.exec(title);
    //   if (!match || !match[1]) {
    //     warn('status.invalid_open_formular');
    //   } else {
    //     const params = match[1];
    //     let arr = params.split(',');
    //     if (arr.length < 2) {
    //       arr = params.split(';');
    //       // if (arr.length < 2) {
    //       //   error('status.invalid_formula');
    //       //   return null;
    //       // }
    //     }

    //     const [_1, _2, ...headers] = arr;
    //     const shortCmd = (_1 || '').trim();
    //     const primaryKey = (_2 || '').trim();
    //     const fields = headers
    //       ? headers.reduce((acc, v) => {
    //           const parts = v.split(':');
    //           const fieldName = (parts[0] || '').trim();
    //           if (fieldName) {
    //             acc[fieldName] = (parts[1] || '').trim();
    //           }
    //           return acc;
    //         }, {})
    //       : {};

    //     return run(
    //       {
    //         action: Command.OPEN,
    //         fn: shortCmd,
    //         data: { _key: primaryKey, _text: text, ...fields },
    //       },
    //       vm,
    //       tbar
    //     );
    //   }
    // }

    // Check if it is JOURNAL formula
    re = /^INVOICING\s*\([\s\w,;&()]+\)$/i;
    if (re.test(title)) {
      return runInvoiceCommand(view, vm, tbar, title);
    }
  }
};

export const defer = (delayTime, fn, args) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(fn(args));
    }, delayTime);
  });
};

export const defferIf = async (skip, fn, args) => {
  let wait = await fn(args);
  while (skip === wait) {
    wait = await defer(1000, fn, args);
  }

  console.log('wait......................', wait);

  return wait;
};

export const safeExecute = (fn) => {
  defferIf(false, () => {
    if (!window.LEGACY_APP_READY) {
      return false;
    }
    fn();
    return true;
  });
};

export const download = async (fileName, options) => {
  await executePromise(
    new Promise(
      (resolve, rejact) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.open('POST', 'api/router.php');
        xhr.onload = async function (e) {
          if (this.status == 200) {
            const blob = new Blob([this.response], {
              type: 'application/vnd.ms-excel',
            });
            const a = document.createElement('a');
            a.setAttribute('class', 'x-hidden');
            // a.style = "display: none";
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            a.click();

            window.URL.revokeObjectURL(url);

            resolve({ success: true, message: null });
          } else if (this.status == 503) {
            const blob = new Blob([this.response], {
              type: 'text/html',
            });
            const responseText = await blob.text();
            try {
              const response = JSON.parse(responseText);
              checkSessionTimeout(response.result);
              resolve(response.result);
            } catch (e) {
              console.log('Error', e);
              resolve({
                success: false,
                message: 'status.service_unavailable',
              });
            }
          } else {
            //deal with your error state here
            resolve({ success: false, message: null });
          }
        };
        xhr.onerror = (event) => {
          resolve({ success: false, message: null });
          // alert('Oops! Something went wrong.');
        };

        const frmData = new FormData();
        for (const [name, value] of Object.entries(options)) {
          frmData.append(name, value);
        }

        // Send our FormData object; HTTP headers are set automatically
        xhr.send(frmData);
      },
      {
        loading: 'status.downloading',
        success: () => null,
      }
    )
  );
};

export const updateLocalStorage = (config) => {
  if (!config?.login) {
    return localStorage.clear();
  }

  for (const key in config) {
    if (config.hasOwnProperty(key)) {
      if (key === 'menu') {
        if (config[key]) {
          const menu = JSON.stringify(config[key]);
          appStore.menu = menu;
          localStorage.setItem(key, menu);
        }
      } else if (key === 'license') {
        appStore.license = config['license'];
        const license = JSON.stringify(config[key]);
        localStorage.setItem('license', license);
      } else {
        localStorage.setItem(key, config[key]);
      }
    }
  }
};

export const readLocalStorage = () => {
  const login = localStorage.getItem('login');
  const failure = localStorage.getItem('failure');

  if (!login) {
    return { login, failure };
  }

  const language = localStorage.getItem('language');
  const homepage = localStorage.getItem('homepage');
  const operatorname = localStorage.getItem('operatorname') || '';
  const chainid = localStorage.getItem('chainid');
  const countryid = localStorage.getItem('countryid');
  const wallet = localStorage.getItem('wallet');
  const moneta = localStorage.getItem('moneta') || '';
  const roleid = localStorage.getItem('roleid');
  const operatorid = localStorage.getItem('operatorid');
  const account = localStorage.getItem('account');
  const dateformat = localStorage.getItem('dateformat');
  const email = localStorage.getItem('email') || '';
  const captcha = localStorage.getItem('captcha') || '';
  const activeledger = localStorage.getItem('activeledger') || 'A';

  appStore.license = localStorage.getItem('license') || '{"LA": 0}';
  // const license = JSON.parse(licenseJson);

  appStore.menu = localStorage.getItem('menu') || '[]';

  return {
    login: login === 'true',
    failure,
    language,
    homepage,
    operatorname,
    chainid,
    countryid,
    wallet,
    moneta,
    roleid,
    operatorid,
    account,
    dateformat,
    email,
    captcha,
    activeledger,
    license: JSON.parse(appStore.license),
  };
};

export const reloadApp = () => {
  updateLocalStorage({ login: false });
  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const humanizeNumber = (value, decimals = 2, minimumDecimals = 0) => {
  if (value || value === 0) {
    const num = Math.abs(value);
    const scale = Math.pow(10, decimals);
    const numberFloat = Math.round(num * scale) / scale; // parseFloat(num).toFixed(decimals)
    let numberString = numberFloat.toLocaleString('fullwide', {
      useGrouping: true,
      minimumSignificantDigits: 1,
    });

    if (minimumDecimals && typeof minimumDecimals === 'number') {
      let decimalPlace = numberString.indexOf('.');
      if (decimalPlace === -1) {
        numberString += '.';
        decimalPlace = numberString.indexOf('.');
      }

      const decimalPlaces = numberString.slice(decimalPlace + 1).length;
      let remainingDecimalPlaces = minimumDecimals - decimalPlaces;
      while (remainingDecimalPlaces > 0) {
        numberString += '0';
        remainingDecimalPlaces -= 1;
      }
    }
    return numberString;
  }
};

let exportExcelCount = 1;
export const saveJSON2Excel = (filename, data) => {
  const header = Object.keys(data[0]);
  const fileName = `${filename}_${exportExcelCount++}.xlsx`;
  const ws = XLSX.utils.json_to_sheet(data, { header });
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, fileName);
};
