export const convertChartRawData = (data = []) => {
  return data.reduce((acc, v) => {
    const keys = Object.keys(v);
    keys.forEach((k) => {
      if (!acc[k]) {
        acc[k] = [v[k]];
      } else {
        acc[k].push(v[k]);
      }
    });
    return acc;
  }, {});
};
export const transformChartData = (meta, dataSources) => {
  const data = {};
  const columnNames = [
    'x',
    'y',
    'z',
    'r',
    'u',
    'v',
    'w',
    'a',
    'b',
    'c',
    'i',
    'j',
    'k',
    'facecolor',
    'intensity',
    'vertexcolor',
    'theta',
    'labels',
    'values',
    'columnorder',
    'columnwidth',
    'cells',
    'header',
    'starts',
    'lat',
    'lon',
    'locations',
    'parents',
    'node',
    'ids',
    'open',
    'close',
    'low',
    'high',
    'measure',
  ];

  columnNames.forEach((c) => {
    const field = meta?.columnNames ? meta?.columnNames[c] : undefined;
    // console.log('field:', c, field, typeof field);
    if (field === undefined) {
      data[c] = undefined;
    } else {
      let fields;
      if (c === 'cells' || c === 'header') {
        fields = field.values.split(' - ');
      } else if (c === 'starts') {
        fields = Object.keys(field);
      } else if (c === 'node') {
        fields = Object.keys(field);
      } else {
        fields = field.split(' - ');
      }
      // console.log('fields.....', fields);

      if (fields.length === 1) {
        if (c === 'cells' || c === 'header') {
          data[c] = {
            values: dataSources[field?.values],
            valuessrc: [field?.values || ''],
          };
        } else if (c === 'starts') {
          const xsrc = meta.columnNames['x'];
          const ysrc = meta.columnNames['y'];
          const zsrc = meta.columnNames['z'];
          data[c] = {
            x: dataSources[xsrc],
            xsrc,
            y: dataSources[ysrc],
            ysrc,
            z: dataSources[zsrc],
            zsrc,
          };
        } else if (c === 'node') {
          const f = fields[0];
          const fsrc = `${f}src`;
          data[c] = {
            [f]: dataSources[meta.columnNames.node[f]],
            [fsrc]: fsrc,
          };
        } else {
          data[c] = dataSources[field];
        }
      } else {
        data[c] =
          c === 'cells' || c === 'header' ? { values: [], valuessrc: [] } : [];

        for (let i = 0; i < fields.length; i++) {
          if (c === 'cells' || c === 'header') {
            data[c].valuessrc.push(fields[i]);
            data[c].values.push(dataSources[fields[i]]);
          } else if (c === 'starts') {
            const f = fields[i];
            const fsrc = `${f}src`;
            if (!data[c]) {
              data[c] = {
                [f]: dataSources[meta.columnNames[f]],
                [fsrc]: fsrc,
              };
            } else {
              data[c][f] = dataSources[meta.columnNames[f]];
              data[c][fsrc] = fsrc;
            }
          } else if (c === 'node') {
            const f = fields[i];
            const fsrc = `${f}src`;
            if (!data[c]) {
              data[c] = {
                [f]: dataSources[meta.columnNames.node[f]],
                [fsrc]: fsrc,
              };
            } else {
              data[c][f] = dataSources[meta.columnNames.node[f]];
              data[c][fsrc] = fsrc;
            }
          } else {
            data[c].push(dataSources[fields[i]]);
          }
        }
      }
    }
  });
  console.log('data.......', data);
  return data;
};

export const getGraphCfg = (graph, layout) => {
  const traces = [];
  graph.forEach((g) => {
    const {
      x,
      y,
      r,
      z,
      u,
      v,
      w,
      a,
      b,
      c,
      i,
      j,
      k,
      facecolor,
      intensity,
      vertexcolor,
      theta,
      labels,
      values,
      columnorder,
      columnwidth,
      cells,
      header,
      starts,
      lat,
      lon,
      locations,
      parents,
      node,
      ids,
      open,
      close,
      low,
      high,
      measure,
      ...others
    } = g;

    traces.push({ ...others });
  });

  const cfg = JSON.stringify({
    traces,
    layout,
  });

  return cfg;
};
