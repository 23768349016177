import { Box, CircularProgress } from '@mui/material';

const LoadingFullpage: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9999,
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        top: 0,
        left: 0,
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

export default LoadingFullpage;
