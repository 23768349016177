import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, MenuItem, InputLabel, Select } from '@mui/material';

import { useGetCountriesQuery } from 'features/FrmUtils';

interface IFormValues {
  country: number;
}

// const CountrySelect: React.FC<
//   FormControlProps & {
//     name: string;
//     inputRef?: any;
//     selectProps?: SelectProps;
//   }
// > = (props) => {
// const CountrySelect = React.forwardRef<
//   HTMLSelectElement,
//   { label?: string, selectProps?: SelectProps } & ReturnType<UseFormRegister<IFormValues>>
// >(({ onChange, onBlur, name, label }, ref) => {
const CountrySelect = React.forwardRef<{ o: any }, any>(
  (
    {
      onChange,
      onBlur,
      name,
      value,
      defaultValue,
      label,
      selectProps,
      ...others
    },
    ref
  ) => {
    const { t } = useTranslation();
    // const { selectProps, name, inputRef, ...others } = props;
    // console.log('props:...', props);

    // TODO: show loading
    const { data: countries = [] } = useGetCountriesQuery();

    // const selectEl = useMemo(
    //   () => (
    //     <Select
    //       {...selectProps}
    //       name={name}
    //       id="country-select"
    //       labelId="country-select-label"
    //       size="small"
    //     >
    //       {countries.map((country) => (
    //         <MenuItem key={country.countryid} value={country.countryid} dense>
    //           {country.printable_name}
    //         </MenuItem>
    //       ))}
    //     </Select>
    //   ),
    //   [countries, selectProps, name]
    // );

    // {...selectProps}
    return (
      <FormControl variant="standard" size="small" fullWidth {...others}>
        <InputLabel id="country-select-label" size="small">
          {t('countries.title_plural')}
        </InputLabel>
        <Select
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          id="country-select"
          labelId="country-select-label"
          size="small"
          {...selectProps}
        >
          {countries.map((c) => (
            <MenuItem key={c.countryid} value={c.countryid} dense>
              {c.printable_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    // return (
    //   <FormControl variant="standard" size="small" fullWidth {...props}>
    //     <InputLabel id="country-select-label" size="small">
    //       {t('countries.title_plural')}
    //     </InputLabel>
    //     {control ? (
    //       <Controller as={selectEl} name={name} control={control} size="small"/>
    //     ) : (
    //       selectEl
    //     )}
    //   </FormControl>
    // );
  }
);

CountrySelect.displayName = 'CountrySelect';
export default CountrySelect;
