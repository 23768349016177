import { proxy } from 'valtio';

export const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

const state = proxy<{
  metamask: boolean;
  chainId: string;
  l1ChainId: string;
  l2ChainId: string;
  l1Rpc: string;
  l2Rpc: string;
  open: boolean;
  session: any;
  args: any;
  authorization: Array<any>;
  selected: string;
  version: number;
  uri: string;
}>({
  metamask: isMetaMaskInstalled(),
  chainId: 'eip155:1337', // 'eip155:5',
  l1ChainId: 'eip155:1',
  l2ChainId: 'eip155:324',
  l1Rpc: '',
  l2Rpc: '',
  open: false,
  session: null,
  args: null,
  authorization: [],
  selected: '',
  version: 0,
  uri: '',
});

export default state;
