import React, { useEffect, lazy } from 'react';
import { useSnapshot } from 'valtio';
import { makeStyles } from 'tss-react/mui';
import { Calculator } from 'react-mac-calculator';
import {
  createWindow,
  showWindow,
  hasWindow,
  closeWindow,
} from 'store/windowStore';

import AppWindowContainer from './AppWindowContainer';
import windowStore, { calculator } from 'store/windowStore';

const AppWindow: React.FC = () => {
  const { classes, cx } = useStyles();
  const state = useSnapshot(windowStore);
  const windows = state.windows;

  // if (windows.length === 0) {
  //   return <AppLoader />;
  // }

  useEffect(() => {
    document.addEventListener(
      'keyup',
      (e) => {
        if (e.key === 'Escape') {
          // e.stopPropagation();
          e.stopImmediatePropagation();

          if (!hasWindow(calculator)) {
            createWindow(
              calculator,
              {
                title: 'Calculator',
                Component: Calculator,
              },
              false,
              {
                x: 100,
                y: 100,
                width: 320,
                height: 508,
              }
            );
          } else {
            closeWindow(calculator);
          }

          return false;
        } else if (
          hasWindow(calculator) &&
          [
            '0',
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '.',
            '+',
            '-',
            '*',
            '/',
            '*',
          ].includes(`${e.key}`)
        ) {
          showWindow(calculator);
        }
      },
      false
    );
  }, []);
  return (
    <div id="root-appWindow" className={classes.appWindowRoot}>
      {Array.from(windows.keys()).map((id) => (
        <AppWindowContainer
          key={id}
          id={id}
          maximized={windows.get(id)?.maximized}
          initial={windows.get(id)?.initial}
        />
      ))}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  appWindowRoot: {
    // position: 'fixed',
    // pointerEvents: 'none',
    //width: '100%',
    //height: '100%',
    //left: 0,
    //top: 0,
    overflow: 'hidden', // Needed for inner window scroll
  },
}));

export default AppWindow;
