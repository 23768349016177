import React from 'react';
import { TextField, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

// export const LocalizedDatePicker: React.FC<{
//   label?: string;
//   minDate?: Date;
//   control: Control;
//   name: string;
//   defaultValue: Date;
//   required?: boolean;
// }> = ({ control, name, defaultValue, required, ...props }) => {
const LocalizedDatePicker = React.forwardRef<{ o: any }, any>(
  (
    {
      onChange,
      onBlur,
      name,
      value,
      defaultValue,
      label,
      required,
      datePickerProps,
      ...others
    },
    ref
  ) => {
    const dateFormat = localStorage.getItem('dateformat') || 'MM/DD/YYYY';


    return (
      <FormControl variant="standard" size="small" {...others}>
        <DatePicker
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          label={label}
          format={dateFormat
            .replace('DD', 'dd')
            .replace('mm', 'MM')
            .replace('Y', 'y')}
          renderInput={(params) => (
            <TextField name={name} required={required} {...params} />
          )}
          {...datePickerProps}
        />
      </FormControl>
    );

    // return (
    //   <Controller
    //     control={control}
    //     name={name}
    //     defaultValue={defaultValue}
    //     render={({ onChange, value, name, ref }) => {
    //       console.log('config?.dateformat', config?.dateformat);
    //       const dateFormat = config?.dateformat || 'MM/DD/YYYY';
    //       return (
    //         <DatePicker
    //           onChange={onChange}
    //           value={value}
    //           inputFormat={dateFormat.replace('DD', 'dd').replace('mm', 'MM').replace('Y','y')}
    //           renderInput={(params) => (
    //             <TextField name={name} required={required} {...params} />
    //           )}
    //           {...props}
    //         />
    //       );
    //     }}
    //   />
    // );
  }
);

LocalizedDatePicker.displayName = 'LocalizedDatePicker';
export default LocalizedDatePicker;
