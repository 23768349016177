// @ts-nocheck
import React from 'react';
import { createRoot } from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import './i18n';
import App from './App';

declare global {
  interface Window {
    ethereum: any;
    LEGACY_APP_LOADED: boolean;
    LEGACY_APP_READY?: boolean;
    LEGACY_SYSTEM_INITIALIZED?: boolean;
  }
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
