import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { ArrowForward } from '@mui/icons-material';
import { Typography, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ErrorPage: React.FC<{ statusCode: number; message?: string }> = ({
  message,
  statusCode,
}) => {
  const { t } = useTranslation();

  const { classes } = useStyles();

  const defaultMessage: Record<number, string> = {
    404: t('common.404.title'),
    403: t('common.403.title'),
    500: t('common.500.title'),
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h2">
        {message || defaultMessage[statusCode]}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: 12,
        }}
      >
        <Button
          component={Link}
          to="/"
          endIcon={<ArrowForward />}
          disableRipple
          className={classes.button}
          size="large"
          color="primary"
        >
          {t('common.navigation.go_home')}
        </Button>
        <Button
          component={Link}
          to="/contact/support"
          endIcon={<ArrowForward />}
          disableRipple
          className={classes.button}
          color="primary"
        >
          {t('action.support_contact')}
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles()((theme) => ({
  root: {
    margin: theme.spacing(24, 8),
    textAlign: 'center',
  },
  button: {
    borderRadius: 9999,
    margin: theme.spacing(0, 1),
  },
}));

export default ErrorPage;
